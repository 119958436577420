import React from "react"
import styled from "styled-components"

import { DesktopContent } from "@components/elements/ResponsiveContent"

import logo from "@assets/logo.svg"

const LogoImg = styled.img`
  height: var(--navbar-logo-height);
`

const LogoNavbarWrapper = styled.nav`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0 8px 0;
`

export default function Element() {
  return (
    <DesktopContent style={{ position: "sticky", top: "0" }}>
      <LogoNavbarWrapper>
        <LogoImg src={logo} />
      </LogoNavbarWrapper>
    </DesktopContent>
  )
}
