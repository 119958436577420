export default {
  name: "Project/Program Management",
  slug: "project-program-management",
  jobFamilies: [
    {
      name: "Project/Program Management Generalist/Multidiscipline",
      slug: "project-program-management-generalist-multidiscipline",
      descriptions: [
        "Responsibilities are within the Project/Program Management Function as a generalist or in a combination of Disciplines",
        "Plans, monitors and manages internal projects from initiation through completion",
        "Leads or coordinates project planning, resourcing, staffing, supply and subcontract management, progress reporting, troubleshooting and people management",
        "Ensures project results meet requirements regarding technical quality, reliability, schedule and cost",
        "Monitors performance and recommends schedule changes, cost adjustments or resource additions",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Cost Management",
        "Project and Program Management",
        "Risk Management",
        "Schedule Management",
        "Stakeholder Management",
        "Contract Management",
        "Issue Management",
        "Project Controls",
        "Resource Optimization",
        "Vendor Management",
      ],
      potentialMovements: [
        {
          source: {
            jobNames: ["Mgr Program Management", "Mgr Strategic Projects"],
          },
          destinations: [
            {
              jobFamilySlug: "distribution-project-management",
              jobNames: ["Mgr Project Management"],
            },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
        {
          source: {
            jobNames: ["Project Manager I", "Project Management Analyst I"],
          },
          destinations: [
            {
              jobFamilySlug: "construction-project-management",
              jobNames: ["Project Manager I"],
            },
            {
              jobFamilySlug: "distribution-project-management",
              jobNames: ["Project Manager II"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Construction Project Control Services",
      slug: "construction-project-control-services",
      descriptions: [
        "Collaborates with project managers on the development and review of project plans, estimates, costs, contracts, schedules and resources",
        "Develops and implements project controls and monitoring procedures to ensure the achievement of project scope, schedule and cost objectives",
        "Collects, analyzes and reports on project data and costs from various stakeholders (e.g., engineering, procurement, construction and accounting)",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [1, 2],
          levelText: "P1 - P2",
        },
      ],
      topSkills: [
        "Cost Management",
        "Project and Program Management",
        "Risk Management",
        "Schedule Management",
        "Construction Management",
        "Contract Management",
        "Project Controls",
        "Project Management Governance",
        "Quality Management",
        "Records Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Utility Constr Contract Specialist II"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Construction Project Management",
      slug: "construction-project-management",
      descriptions: [
        "Leads or coordinates strategic / special construction projects",
        "Leads or coordinates facilities construction projects including developing a new facility; the addition, expansion or extension of an existing facility; or renovation/alterations to a facility in collaboration with project team, construction site and management",
        "Develops assignments, timetables and responsibilities for team members for the duration of the project",
        "Organizes and directs construction personnel and ensures that materials and equipment resources are delivered on time",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Cost Management",
        "Project and Program Management",
        "Risk Management",
        "Schedule Management",
        "Stakeholder Management",
        "Change Management",
        "Construction Management",
        "Contract Management",
        "Project Controls",
        "Project Management Governance",
        "Quality Management",
      ],
      potentialMovements: [
        {
          source: {
            jobNames: ["Lead Project Manager", "Lead Construction Consultant"],
          },
          destinations: [
            {
              jobFamilySlug:
                "project-program-management-generalist-multidiscipline",
              jobNames: ["Mgr Strategic Projects", "Mgr Program Management"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Sr Project Manager"] },
          destinations: [
            {
              jobFamilySlug: "distribution-project-management",
              jobNames: ["Mgr Project Management"],
            },
            {
              jobFamilySlug:
                "project-program-management-generalist-multidiscipline",
              jobNames: ["Mgr Strategic Projects", "Mgr Program Management"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Project Manager II"] },
          destinations: [
            {
              jobFamilySlug: "distribution-project-management",
              jobNames: ["Project Manager II", "Mgr Project Management"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Project Manager I"] },
          destinations: [
            {
              jobFamilySlug:
                "project-program-management-generalist-multidiscipline",
              jobNames: ["Project Manager I", "Project Management Analyst I"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Distribution Project Management",
      slug: "distribution-project-management",
      descriptions: [
        "Leads or coordinates strategic / special construction projects",
        "Leads or coordinates facilities construction projects including developing a new facility; the addition, expansion or extension of an existing facility; or renovation/alterations to a facility in collaboration with project team, construction site and management",
        "Develops assignments, timetables and responsibilities for team members for the duration of the project",
        "Organizes and directs construction personnel and ensures that materials and equipment resources are delivered on time",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2],
          levelText: "P1 - P2",
        },
      ],
      topSkills: [
        "Cost Management",
        "Project and Program Management",
        "Schedule Management",
        "Stakeholder Management",
        "Construction Management",
        "Health, Safety and Environmental (HSE) Management",
        "Materials Management",
        "Project Controls",
        "Project Management Governance",
        "Quality Management",
        "Resource Optimization",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Mgr Program Management"] },
          destinations: [
            {
              jobFamilySlug:
                "project-program-management-generalist-multidiscipline",
              jobNames: ["Mgr Project Management", "Mgr. Strategic Projects"],
            },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Project Manager II"] },
          destinations: [
            {
              jobFamilySlug: "construction-project-management",
              jobNames: ["Project Manager II", "Sr Project Manager II"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Information Technology Project Management",
      slug: "information-technology-project-management",
      descriptions: [
        "Manages all aspects of a diverse IT project or multiple IT projects, typically involving multiple Disciplines in the IT Function",
        "Utilizes expertise and leadership skills to direct staff and to resolve issues to ensure project goals and requirements are met",
        "Typically uses Scrum/Agile development techniques and tools for team collaboration, issue tracking and backlog management",
      ],
      jobLevels: [],
      topSkills: [
        "Agile Methodology",
        "Cost Management",
        "Process Automations",
        "Project and Program Management",
        "Requirements Management",
        "Risk Management",
        "Schedule Management",
        "Solutions Design",
        "Stakeholder Management",
        "Technical Reports",
      ],
      potentialMovements: [],
    },
  ],
}
