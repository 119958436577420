import React from "react"
import styled from "styled-components"
import { makeStyles } from "@material-ui/core/styles"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"

import Autocomplete from "@material-ui/lab/Autocomplete"
import SearchIcon from "@material-ui/icons/Search"
import searchData from "@constants/search"
import ListSubheader from "@material-ui/core/ListSubheader"
import { navigate } from "@reach/router"

import theme from "@assets/styles/theme"

const SearchFlex = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 8px;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

const TypeaheadInputWrapper = styled.div`
  width: 100%;
  position: relative;

  > .search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${props => (props.small ? "12px" : "16px")};
    width: auto;
    height: ${props => (props.small ? "16px" : "20px")};

    color: ${props => props.theme.colors.grey.text};
  }
`

const TypeaheadInput = styled.input`
  padding: 0 1rem;
  padding-left: ${props => (props.small ? "40px" : "52px")};
  /* width: calc(100% - 2rem); */
  width: 100%;
  height: ${props => (props.small ? "36px" : "48px")};
  border-radius: 30px;
  background: white;
  border: ${props => (props.small ? "1px solid rgba(44, 44, 44, 0.25)" : "0")};
  font-size: ${props => (props.small ? "12px" : "16px")};
`

const renderGroup = params => [
  <ListSubheader
    key={params.key}
    style={{
      background: "#E8F6FB",
      padding: "9px 15px",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: "400",
      color: "#046FA8",
    }}
    component="ul"
  >
    {params.group}
  </ListSubheader>,
  params.children,
]

// override padding
const useStyles = makeStyles({
  paper: props =>
    props.mobileNav
      ? {
          position: "fixed",
          borderRadius: 0,
          margin: "16px -14px",
          boxShadow: "none",
          height: "calc(100vh - 120px)",
          width: "100vw",
        }
      : {
          borderRadius: "30px",
        },
  listbox: props =>
    props.mobileNav
      ? {
          margin: 0,
          padding: 0,
          listStyle: "none",
          maxHeight: "100%",
          height: "100%",

          fontSize: "14px",
          fontFamily: "Roboto",
        }
      : {
          margin: 0,
          padding: 0,
          overflow: "auto",
          listStyle: "none",
          maxHeight: "40vh",

          fontSize: "14px",
          fontFamily: "Roboto",
        },
  option: {
    padding: 0,
    "&+ul": {
      marginTop: "-1px",
    },
  },
})

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

const ListItemTop = styled.div`
  padding: 16px 52px 0 52px;
  font-size: 16px;
  line-height: 18px;

  &.small {
    font-size: 12px;
    padding-top: 12px;
    padding: 12px 39px 0 39px;
  }
`

const ListItemBottom = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: ${theme.colors.grey.text};
  margin-top: 4px;
  padding-left: 52px;

  &.small {
    font-size: 10px;
    padding: 0 39px;
  }
`

const ListItemBorder = styled.span`
  height: 1px;
  width: calc(100% - 32px);
  margin-left: 16px;
  background-color: ${theme.colors.grey.grey};
  margin-top: 16px;
`

const SearchTypeahead = props => {
  // const [searchValue, setSearchValue] = React.useState("")
  const classes = useStyles(props)

  const handleSearchSelect = (event, value) => {
    const currentWindowWidth = window.innerWidth
    if (value.mobileTo && currentWindowWidth < 1023) {
      navigate(value.mobileTo)
    } else {
      navigate(`${value.to}`)
    }
  }

  const renderResultBox = (option, inputValue) => {
    const matches = match(option.name, inputValue)
    const parts = parse(option.name, matches)

    const { name, groupName, levelString, to } = option

    const secondaryText = `${groupName} | Levels: ${levelString}`

    return (
      <ListItem>
        <ListItemTop className={props.small ? "small" : ""}>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
                color: part.highlight ? "#046FA8" : "#3F3F3F",
              }}
            >
              {part.text}
            </span>
          ))}
        </ListItemTop>
        {option.category === "Job Family" && (
          <ListItemBottom className={props.small ? "small" : ""}>
            {secondaryText}
          </ListItemBottom>
        )}
        <ListItemBorder />
      </ListItem>
    )
  }

  const onOpenSearch = () => {
    if (props.onOpenSearch) {
      props.onOpenSearch()
    }
  }

  const onCloseSearch = () => {
    if (props.onCloseSearch) {
      props.onCloseSearch()
    }
  }

  return (
    <SearchFlex>
      <Autocomplete
        // freeSolo
        options={searchData}
        getOptionLabel={option => option.name}
        onChange={handleSearchSelect}
        onOpen={onOpenSearch}
        onClose={onCloseSearch}
        classes={classes}
        style={props.style}
        groupBy={option => option.category}
        renderGroup={renderGroup}
        renderOption={(option, { inputValue }) => {
          return renderResultBox(option, inputValue)
        }}
        renderInput={params => (
          <TypeaheadInputWrapper
            ref={params.InputProps.ref}
            small={props.small}
          >
            {/* <img src={searchIcon} alt="" className="search-icon" /> */}
            <SearchIcon className="search-icon" />
            <TypeaheadInput
              type="text"
              grey={props.grey}
              small={props.small}
              {...params.inputProps}
              placeholder="Search for Job Family"
            />
          </TypeaheadInputWrapper>
        )}
      />
    </SearchFlex>
  )
}

export default SearchTypeahead
