export default {
  name: "Customer Solutions and Products",
  slug: "customer-solutions-and-products",
  jobFamilies: [
    {
      name: "Product Management",
      slug: "product-management",
      descriptions: [
        "Develops, promotes and implements products and offerings, including new products and enhancements to existing products or strategic product extensions, and translates research discoveries into usable and marketable products in the context of business strategy and regulatory environment",
        "Manages and executes processes and strategies including processes, tools, technology to operationalize product offerings and manage a product or product category’s life cycle within regulated environment",
        "Develops internal cross-functional relationships including regulatory, legal, accounting, engineering, marketing, communications, and customer service areas in addition to interfacing externally with customers, suppliers and vendors.",
        "Assimilates information and research from customers, competitors, suppliers, industry agencies, and other sources to maintain an in-depth understanding of utility industry, emerging energy technology, and regulatory trends",
        "Ensures that programs are in compliance with legislative and regulatory guidelines and may be responsible for Public Utility Commission (PUC) funds awarded",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Customer Service Management",
        "Data Analysis",
        "Product Development",
        "Product Strategies",
        "Program Evaluations",
        "Project and Program Management",
        "Regulatory Compliance",
        "Technical Evaluation",
        "Technical Reports",
        "Vendor Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Programs Consultant"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Sr Distributed Gen Svc Specialist"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Distributed Gen Svc Specialist II"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Product Specialist I"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
  ],
}
