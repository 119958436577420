export default {
  name: "Audit & Financial/Business Controls",
  slug: "audit-financial-business-controls",
  jobFamilies: [
    {
      name: "Audit and Financial/Business Controls Generalist/Multidiscipline",
      slug: "audit-and-financial-business-controls-generalist-multidiscipline",
      descriptions: [
        "Responsibilities are within the Audit and Financial/Business Controls Function as a generalist or in a combination of Disciplines",
        "Evaluates the effectiveness of internal controls in compliance with corporate objectives and government standards such as the Sarbanes-Oxley Act (SOX)",
        "Reviews, evaluates, develops, implements, maintains and verifies internal controls for processes, systems, financial statements, journals and reports in accordance with internal auditing and government-mandated standards",
        "Identifies internal control gaps in business procedures, processes or systems and initiates appropriate remedial action",
        "Responsible for reviewing and updating documentation of controls processes across the organization",
        "May coordinate with external auditors to prepare the attestation of management assessment on internal controls in accordance with Section 404 of the Sarbanes-Oxley Act",
        "Serves in an advisory capacity for controls-related projects",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [3, 4, 5],
          levelText: "P3 - P5",
        },
      ],
      topSkills: [
        "Internal Controls",
        "Regulatory Compliance",
        "Accounting Standards",
        "Business Process Improvements",
        "Change Management",
        "Corporate Financial Reporting",
        "Data Visualization",
        "Enterprise Risk Management",
        "Financial Analysis",
        "Financial Risk Management",
        "Internal Control Design",
        "Policy Design",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Regltry Solutions Proj Manager"] },
          destinations: [
            { jobFamilySlug: "general-audit", jobNames: [] },
            {
              jobFamilySlug: "finance-generalist-multidiscipline",
              jobNames: [],
            },
            { jobFamilySlug: "general-accounting-reporting", jobNames: [] },
            { jobFamilySlug: "tax-planning-reporting", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Sr NERC Stand&Compl Specialist"] },
          destinations: [
            { jobFamilySlug: "general-audit", jobNames: [] },
            {
              jobFamilySlug: "finance-generalist-multidiscipline",
              jobNames: [],
            },
            { jobFamilySlug: "general-accounting-reporting", jobNames: [] },
            { jobFamilySlug: "tax-planning-reporting", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["NERC Stand&Cmpl Specialist II"] },
          destinations: [
            { jobFamilySlug: "general-audit", jobNames: [] },
            {
              jobFamilySlug: "finance-generalist-multidiscipline",
              jobNames: [],
            },
            { jobFamilySlug: "general-accounting-reporting", jobNames: [] },
            { jobFamilySlug: "tax-planning-reporting", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
    {
      name: "General Audit",
      slug: "general-audit",
      descriptions: [
        "Develops, plans and evaluates internal audit programs for the organization's accounting and statistical records and the activities of various departments to evaluate compliance with the organization's policies, procedures and standards",
        "Assess management’s accountability of assets",
        "Audits accounting records of contract agreements in which the organization is involved",
        "Prepares reports for management on the results of audits, providing recommendations on improvements",
        "Interfaces with and assists outside auditors to expedite their work",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3],
          levelText: "P1 - P3",
        },
      ],
      topSkills: [
        "Internal Controls",
        "Regulatory Compliance",
        "Accounting Standards",
        "Audit Management",
        "Audit Testing",
        "Enterprise Risk Management",
        "Financial Analysis",
        "Financial Auditing",
        "Fraud Prevention",
        "Internal Control Design",
        "Strategic Audit",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Internal Auditor"] },
          destinations: [
            {
              jobFamilySlug:
                "audit-and-financial-business-controls-generalist-multidiscipline",
              jobNames: [],
            },
            { jobFamilySlug: "is-audit", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Internal Auditor II"] },
          destinations: [
            {
              jobFamilySlug:
                "audit-and-financial-business-controls-generalist-multidiscipline",
              jobNames: [],
            },
            { jobFamilySlug: "is-audit", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
    {
      name: "IS Audit",
      slug: "is-audit",
      descriptions: [
        "Develops, directs, plans and evaluates internal audit programs for the organization's information systems and related procedures to evaluate compliance with the organization's policies, procedures and standards",
        "Audits information systems applications to evaluate that appropriate controls exist and that information produced by the system is accurate",
        "Advises others on information systems, internal controls and security procedures",
        "Prepares reports and recommendations for management on the results of information systems audits",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [3],
          levelText: "P3",
        },
      ],
      topSkills: [
        "Internal Controls",
        "Audit Management",
        "Audit Testing",
        "Change Management",
        "Cybersecurity Strategies",
        "Data Analysis",
        "Information Security Management",
        "Information Technology Auditing",
        "Internal Control Design",
        "Process Automations",
        "Strategic Audit",
        "System Testing",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr IT Audit Risk Consultant"] },
          destinations: [{ jobFamilySlug: "general-audit", jobNames: [] }],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
      ],
    },
    {
      name: "Regulatory Affairs (State, Federal, and Regional)",
      slug: "regulatory-affairs-state-federal-and-regional",
      descriptions: [
        "Maintains the organization's ongoing relationships with regulatory commissions/authorities",
        "Develops and implements the organization's positions on regulatory and policy issues regarding the impact of the organization's operations",
        "Evaluates, implements and maintains the organization's compliance with regulatory rules and standards for technical areas that include but are not limited to operations, planning, and siting",
        "Coordinates and conducts the assessment of internal controls to ensure compliance as required by regulatory commissions/authorities",
        "Develops programs and processes to manage complaint cases brought to regulatory authorities and develops process improvements to avoid future complaints",
        "Collaborates and advocates with internal and external stakeholders to advance regulatory priorities",
        "Manages all aspects of the regulatory process (e.g., docket coordination), including preparing and sponsoring testimony to governmental or regulatory agencies",
        "Coordinates technical, regulatory, and audit resources to design and consistently implement North American Electric Reliability Corporation (NERC) compliance solutions across all affected business units and stakeholders",
        "Provides expertise and coordinates the NERC CIP and/or Operations and Planning Compliance Program, which ensures that Company facilities are identified and protected in compliance with the required standards and policies, including observing and enforcing compliance practices, encouraging secure work behaviors, and promptly correcting conditions and insecure behaviors that may lead to reliability incidents",
        "Manage technical standards included but not limited to CIP, Reliability, and Safety",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Internal Controls",
        "Issue Management",
        "Policy Analysis",
        "Political Strategies",
        "Regulatory Compliance",
        "Regulatory Strategies",
        "Risk Management",
        "Stakeholder Management",
        "Standards Compliance",
        "Strategic Communication",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead NERC StndCompl Specialist"] },
          destinations: [
            {
              jobFamilySlug: "environmental-compliance-and-science",
              jobNames: [],
            },
            {
              jobFamilySlug:
                "environmental-health-and-safety-generalist-multidiscipline",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Sr NERC Stand&Compl Specialist"] },
          destinations: [
            {
              jobFamilySlug: "environmental-compliance-and-science",
              jobNames: [],
            },
            {
              jobFamilySlug:
                "environmental-health-and-safety-generalist-multidiscipline",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["NERC Stand&Cmpl Specialist II"] },
          destinations: [
            {
              jobFamilySlug: "environmental-compliance-and-science",
              jobNames: [],
            },
            {
              jobFamilySlug:
                "environmental-health-and-safety-generalist-multidiscipline",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
  ],
}
