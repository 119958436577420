const theme = {
  colors: {
    // AE colors
    primary: {
      blueDark: "#046FA8",
      blue: "#0585C8",
      skyBlue: "#24ABE3",
      blueLight: "#CDE5F1",
      blueLightest: "#E8F6FB",
    },
    secondary: {
      green: "#74C045",
      lime: "#C3D830",
      greenLight: "#E3F2DA",
      orange: "#FCB119",
      yellow: "#FDCE0D",
      yellowLight: "#FEE600",
    },
    grey: {
      white: "#FFFFFF",
      greyBackground: "#F0F0F0",
      grey: "#D2D2D2",
      text: "#636466",
      textDark: "#3F3F3F",
      black: "#3F3F3F",
    },
  },
}

export default theme
