export default {
  name: "Supply Chain Management",
  slug: "supply-chain",
  jobFamilies: [
    {
      name: "Supply Chain Logistics",
      slug: "supply-chain-logistics",
      descriptions: [
        "Responsibilities are within the Supply Chain and Logistics Function as a generalist or in a combination of Disciplines",
        "Ensures that quantities of incoming and outgoing shipments are correct",
        "Performs supply chain and logistics functions such as material procurement, inventory control, vendor selection and distribution",
        "Creates integrated processes among internal functions (e.g., operations, purchasing and logistics) and outside suppliers",
        "Conducts and/or oversees strategic supply chain analysis",
        "Identifies and recommends opportunities for improving efficiency, effectiveness and capabilities of the supply chain",
        "Develops and implements supply chain designs, models, strategies and/or processes to improve the business",
        "Coordinates incoming and outgoing movement and storage of raw materials that include functions such as warehousing, shop and delivery services to ensure material availability and delivery when needed to meet schedules",
        "Compiles data on order volume, schedules and forecasts and applies statistical methods to estimate future materials requirements",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3],
          levelText: "P1 - P3",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Supply Chain Analytics",
        "Business Process Improvements",
        "Change Management",
        "Inventory Management",
        "Inventory Management Control",
        "Inventory Optimization",
        "Logistics Management",
        "Process Automations",
        "Procurement Management",
        "Quality Management",
        "Stakeholder Management",
        "Supplier Selection",
        "Supply Chain Management",
        "Warehouse Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Mgr Logistics", "Mgr Storeroom Operations"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Procurement Generalist/Multidiscipline",
      slug: "procurement-generalist-multidiscipline",
      descriptions: [
        "Prepares and processes purchase orders for the procurement of goods, services and supplies for customer-related business processes or for internal use as a generalist or in a combination of Purchasing Disciplines",
        "Reviews purchase order claims and contracts for conformance to organization policy, and discusses defects and delivery problems with suppliers and negotiates refunds",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2],
          levelText: "P1 - P2",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Sustainable Supply Chains",
        "Business Negotiations",
        "Business Process Improvements",
        "Change Management",
        "Cost Optimization",
        "Demand Forecasting",
        "Purchase Orders",
        "Supplier Development",
        "Supplier Selection",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Mgr Procurement Operations"] },
          destinations: [
            { jobFamilySlug: "category-management", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Category Management",
      slug: "category-management",
      descriptions: [
        "Negotiates favorable terms, volume discounts and long-term contracts with suppliers",
        "Develops and implements strategies for cost reduction, supplier right-sizing, quality improvements, process efficiencies, and security of supply",
        "Defines category strategies with cross-business teams, based on market intelligence, risk profile and future demand",
        "Recommends value creation opportunities (e.g., strategic sourcing, requirements rationalization, outsourcing)",
        "Executes strategic sourcing initiatives",
        "Manages strategic supplier relationships and track supplier’s performance",
        "Develops and maintains strategic contracts",
        "Acts as the primary interface with the corporate functions, to understand and address their supply chain needs",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3],
          levelText: "P1 - P3",
        },
      ],
      topSkills: [
        "Sustainable Supply Chains",
        "Business Negotiations",
        "Category Management",
        "Category Strategy",
        "Change Management",
        "Contract Lifecycle Management",
        "Contract Management",
        "Cost Analysis",
        "Cost Optimization",
        "Partnership Strategy",
        "Procurement Management",
        "Sourcing Strategies",
        "Stakeholder Management",
        "Supplier Management",
        "Supplier Selection",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Mgr Category Management"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
        {
          source: { jobNames: ["TL Category Management"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "L",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Supply Chain Planning & Governance",
      slug: "supply-chain-planning-governance",
      descriptions: [
        "Develops and stewards Supply Chain Management operating model",
        "Creates and manages functional policies, frameworks and processes; controls compliance; manages change and continuous improvement",
        "Coordinates supply chain strategic planning, budget, and performance management process",
        "Manages supplier quality lifecycle; tracks and reports supplier’s quality compliance",
        "Oversees vendor audits and controls contract compliance",
        "Defines and implements enterprise buying channel strategy for all goods and services",
        "Delivers technology solutions supporting supply chain processes",
        "Owns supplier diversity and ESG related to supply chain",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [3],
          levelText: "P3",
        },
      ],
      topSkills: [
        "Business Process Standardization",
        "eProcurement",
        "Performance Management",
        "Policy Management",
        "Regulatory Compliance",
        "Supplier Evaluations",
        "Supply Chain Analytics",
        "Supply Chain Audit",
        "Sustainable Supply Chains",
        "Technical Reports",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Supply Chain Analyst"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
      ],
    },
  ],
}
