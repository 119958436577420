export default {
  name: "Human Resources",
  slug: "human-resources",
  jobFamilies: [
    {
      name: "Compensation and Benefits",
      slug: "compensation-and-benefits",
      descriptions: [
        "Plans, designs, evaluates and administers employee compensation and benefit programs such as salaries, short- and long-term incentives, job evaluations, performance appraisals, retirement plans, and life, health and disability insurance",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Benefits Administration",
        "Benefits Strategy",
        "Employee Benefits Design",
        "Benchmarking",
        "Compensation Administration",
        "Compensation Strategies",
        "Compensation Structures",
        "Data Analysis",
        "Data Visualization",
        "Equity Compensation",
        "Job Evaluations",
        "Regulatory Compliance",
        "Stakeholder Management",
        "Storytelling",
        "Vendor Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Total Rewards Analyst"] },
          destinations: [
            {
              jobFamilySlug: "hr-operations",
              jobNames: ["Lead HR Analytics Specialist"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Total Rewards Analyst II"] },
          destinations: [
            { jobFamilySlug: "hr-consulting", jobNames: ["HR Consultant II"] },
            {
              jobFamilySlug: "hr-operations",
              jobNames: [
                "HR Compliance Specialist II",
                "HR Operations Consultant II",
              ],
            },
            {
              jobFamilySlug: "recruitment",
              jobNames: ["Talent Acquisition Consultant II"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Total Rewards Analyst I"] },
          destinations: [
            {
              jobFamilySlug: "hr-operations",
              jobNames: ["HR Operations Consultant I"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Employee Development/Training",
      slug: "employee-development-training",
      descriptions: [
        "Develops, implements and evaluates employee development plans and programs to support organizational needs as a generalist or in a combination of Disciplines such as management development, talent management, succession planning, technical or nontechnical training, or e-learning",
        "Monitors employee development and training programs, assesses needs and results, develops new programs and modifies existing programs",
        "Develops and implements training activities directed at both employee competencies and technical skills",
        "Collaborates with other functions (e.g., Engineering, Operations, Maintenance) to ensure that course materials reflect current specifications and to obtain information on new processes and equipment",
        "Develops and deploys computer-based training (CBT) and interactive e-learning courses and training modules",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3],
          levelText: "P1 - P3",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Vendor Management",
        "Content Curation",
        "Continual Improvement Process",
        "Curriculum Development",
        "Data Interpretations",
        "HR Business Partnering",
        "Learning Assessments",
        "Learning Management Systems",
        "Learning Strategies",
        "Training Delivery",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Apprenticeship Training Specialist"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Instructional Design Specialist II"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
    {
      name: "HR Consulting",
      slug: "hr-consulting",
      descriptions: [
        "Anticipates and plans for long-term human resource and workforce planning needs and trends in partnership with business management",
        "Analyzes the organizational structure, determines changes to organizational responsibilities, staffing, managerial skills and the quality of work life",
        "Coordinates employee relations programs to ensure compliance with policies and practices",
        "Develops, implements, and updates processes and procedures, including grievance procedures and exit interviews",
        "Researches and responds to employee questions, concerns and grievances",
        "Maintains employee relations records",
        "Establishes and maintains labor management relations",
        "Represents the organization in contract negotiations with labor unions",
        "Administers the collective bargaining agreements and grievances",
        "Supports and drives DE&I initiatives",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Stakeholder Management",
        "Change Management",
        "Collective Bargaining",
        "Conflict Management",
        "Data Interpretations",
        "Escalation Management",
        "HR Business Partnering",
        "Labor and Employment Law",
        "Labor Management Relations",
        "Organizational Redesign",
        "Performance Management",
        "Talent Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead HR Projects Consultant"] },
          destinations: [
            {
              jobFamilySlug: "hr-generalist-multidiscipline",
              jobNames: ["Mgr Talent Development & Progr"],
            },
            { jobFamilySlug: "hr-operations", jobNames: ["Mgr HR Operations"] },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Lead HR Business Partner"] },
          destinations: [
            { jobFamilySlug: "hr-operations", jobNames: ["Mgr HR Operations"] },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Sr HR Business Partner"] },
          destinations: [
            {
              jobFamilySlug: "employee-development-training",
              jobNames: ["Sr Talent Development Consultant"],
            },
            {
              jobFamilySlug: "hr-operations",
              jobNames: ["Lead HR Analytics Specialist"],
            },
            {
              jobFamilySlug: "recruitment",
              jobNames: ["Sr Talent Acquisition Consultant"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Sr HR Projects Consultant"] },
          destinations: [
            {
              jobFamilySlug: "recruitment",
              jobNames: ["Sr Talent Acquisition Consultant"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["HR Consultant II"] },
          destinations: [
            {
              jobFamilySlug: "hr-operations",
              jobNames: [
                "HR Compliance Specialist II",
                "Sr HR Operations Consultant",
              ],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
    {
      name: "HR Generalist/Multidiscipline",
      slug: "hr-generalist-multidiscipline",
      descriptions: [
        "Responsibilities are within the Human Resources Function as a generalist or in a combination of Disciplines",
        "Designs, implements and monitors a variety of human resource programs and projects, collaborating across subfunctions and teams",
        "Anticipates and plans for long-term human resource and workforce planning needs and trends in partnership with business management",
        "Applies and integrates advanced and predictive analysis, people metrics and reporting to develop strategic and operational insights for workforce decision-making (e.g., staffing, learning and development, talent management, diversity, human resource compliance, DEI, ESG)",
        "Develops, implements and evaluates diversity/equal employment opportunity (EEO) and affirmative action programs to ensure compliance with government legislation and organization goals",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2],
          levelText: "P1 - P2",
        },
      ],
      topSkills: [
        "Stakeholder Management",
        "Change Management",
        "Consulting",
        "Data Interpretations",
        "Employee Engagement",
        "Employer Branding",
        "Human Resources (HR) Transformation",
        "Onboarding",
        "Organization Culture",
        "Performance Management",
        "Project and Program Management",
        "Talent Management",
        "Workplace Transformation",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["DE&I Consultant II"] },
          destinations: [
            { jobFamilySlug: "hr-consulting", jobNames: ["HR Consultant II"] },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
    {
      name: "HR Operations",
      slug: "hr-operations",
      descriptions: [
        "Provides centralized human resource services spanning payroll, benefits and other transactions",
        "Ensures efficiency of service center operations, technology and transaction processes",
        "Establishes standards and procedures for handling employee questions, transactions and administration of human resource programs",
        "Coordinates services with human resource managers",
        "Processes the organization's human resource information using the most efficient and cost-effective computer systems and applications",
        "Researches, analyzes, designs and maintains information systems in support of human resource administration and projects",
        "Monitors HR information needs and designs new or modifies existing systems to meet changing requirements",
        "Applies and integrates advanced and predictive analysis, people metrics and reporting to develop strategic and operational insights for workforce decision-making (e.g., staffing, learning and development, talent management, diversity, human resource compliance, DEI, ESG)",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Data Analysis",
        "Regulatory Compliance",
        "Vendor Management",
        "Business Process Improvements",
        "Customer Experience Management",
        "Data Management",
        "Escalation Management",
        "HR Service Delivery",
        "Operational HR",
        "People Analytics",
        "Policy Analysis",
        "Project and Program Management",
        "Technology Deployments",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr HR Operations Consultant"] },
          destinations: [
            {
              jobFamilySlug: "hr-consulting",
              jobNames: ["Sr HR Projects Consultant"],
            },
            {
              jobFamilySlug: "recruitment",
              jobNames: ["Sr Talent Acquisition Consultant"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["People Systems Analyst II"] },
          destinations: [
            {
              jobFamilySlug: "compensation-and-benefits",
              jobNames: ["Total Rewards Analyst II"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Recruitment",
      slug: "recruitment",
      descriptions: [
        "Sources, recruits, screens, interviews and recommends external and/or internal candidates for all level jobs, including entry level, experienced professional/technical, IT, support staff and hourly, and possibly management",
        "May utilize the services of employment agencies",
        "Places employment ads in appropriate sources, including the Internet and print media",
        "Ensures the maintenance of accurate and concise records and reports concerning all phases of the recruitment process, including EEO statistics",
        "May recruit from colleges, technical schools and job fairs",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3],
          levelText: "P1 - P3",
        },
      ],
      topSkills: [
        "Stakeholder Management",
        "Applicant Tracking Systems",
        "Candidates Management",
        "Data Interpretations",
        "HR Business Partnering",
        "Recruitment Marketing",
        "Talent Branding",
        "Talent Sourcing",
        "Total Rewards Strategies",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Mgr Talent Acquisition"] },
          destinations: [
            {
              jobFamilySlug: "hr-consulting",
              jobNames: ["Lead HR Business Partner"],
            },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Sr Talent Acquisition Consultant"] },
          destinations: [
            { jobFamilySlug: "hr-consulting", jobNames: ["HR Consultant II"] },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Early Talent Consultant II"] },
          destinations: [
            {
              jobFamilySlug: "hr-generalist-multidiscipline",
              jobNames: ["DE&I Consultant II"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Talent Acquisition Consultant II"] },
          destinations: [
            {
              jobFamilySlug: "hr-operations",
              jobNames: [
                "HR Compliance Specialist II",
                "HR Operations Consultant II",
              ],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
  ],
}
