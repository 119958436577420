export default {
  name: "Information Technology",
  slug: "information-technology",
  jobFamilies: [
    {
      name: "Application Configuration & Development",
      slug: "application-configuration-development",
      descriptions: [
        "Designs/develops/configures, tests, debugs, and implements, maintains and upgrade software/web/ERP/CRM applications in accordance with specifications and business needs",
        "Develops program logic for new applications or analyzes and modifies logic in existing applications",
        "Designs, codes, tests, debugs, documents and implements applications using a variety of languages and platforms such as Java, C, C++, C#, Python, PHP, JavaScript, SQL, Scala, Objective-C and Swift",
        "Develops proof-of-concepts and prototypes of easy-to-navigate user interfaces (UI) that consist of graphics, icons and color schemes that are visually appealing",
        "Ensures that applications are compatible across multiple computing platforms and browsers",
        "Conducts a wide range of quality control tests and analyses to ensure that software meets or exceeds specified standards and end user requirements",
        "Drafts, revises and approves test plans and scripts to ensure alignment with standards and IT strategy",
        "Ensures that system tests are successfully completed and documented, and all problems are resolved",
        "Analyzes requirements (including for customization/ configuration), tests and integrates application components",
        "Applies in-depth knowledge of off-the-shelf applications to efficiently customize modules that maintain full compatibility with vendor guidelines",
        "Ensures that application improvements are successfully implemented",
        "Provides ongoing system support",
        'Typically works on a defined project and may work in a highly efficient ("Agile") development environment',
        "May participate in defining requirements and selecting software vendors",
        "Analyzes, researches, develops, maintains and implements enhancements to the energy management system (EMS), the supervisory control and data acquisition (SCADA) system and other electric control center automation systems",
        "Operates and maintains computer and control systems including remote components required for the electric systems operation center to monitor, control and optimize electric system performance to reduce energy consumption, improve system utilization and increase reliability by using real-time data such as frequency, actual generation and load flows",
        "Implements and manages the electric system security program that protects the system from threats in accordance with North American Electric Reliability Corporation (NERC) cyber security standards",
        "Provides detailed electrical power grid security analysis information for field test engineers in diagnosing, troubleshooting and resolving problems with the EMS metering and SCADA subsystems",
        "Maintains in-depth knowledge of new and emerging technologies required to provide long-term enhancements to the reliability, performance and functionality of the EMS and other transmission control center automation systems",
        "Coordinates with the EMS network model engineers on the development and maintenance of the EMS network models",
        "Tests EMS network models, SCADA and software changes to ensure that network applications produce consistent and credible results",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Agile Methodology",
        "API Integration",
        "Artificial Intelligence",
        "Cloud Architectures",
        "Cloud Computing",
        "DevSecOps",
        "Software Development Life Cycle (SDLC)",
        "Software Testing",
        "Stakeholder Management",
        "System Migrations",
        "Systems Integration",
        "Vendor Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead IT Analyst"] },
          destinations: [
            {
              jobFamilySlug: "it-architecture",
              jobNames: ["Lead Solution Architect"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Sr IT Analyst"] },
          destinations: [
            {
              jobFamilySlug: "analytics-data-science",
              jobNames: ["Sr Data Analyst"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["IT Analyst II"] },
          destinations: [
            {
              jobFamilySlug: "analytics-data-science",
              jobNames: ["Data Analyst II"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Business Systems Analysis",
      slug: "business-systems-analysis",
      descriptions: [
        "Acts as a liaison between the IT development group and business units for the development and implementation of new systems and enhancement of existing systems",
        "Evaluates new applications and identifies systems requirements",
        "Evaluates new IT developments and evolving business requirements and recommends appropriate systems alternatives and/or enhancements to current systems",
        "Prepares communications and makes presentations on system enhancements and/or alternatives",
        "Ensures required IT services are identified, developed and supported to the satisfaction of the business unit/division",
        "Establishes and maintains service level agreements and is the main point of contact for all service issues",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Cloud Computing",
        "Continual Improvement Process",
        "Requirements Management",
        "Service Level Agreement (SLA)",
        "Software Testing",
        "Stakeholder Management",
        "System Performance Monitoring",
        "Systems Designs",
        "Systems Integration",
        "User Acceptance Testing",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Business Analyst"] },
          destinations: [
            {
              jobFamilySlug: "it-architecture",
              jobNames: ["Lead Solution Architect"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
      ],
    },
    {
      name: "Analytics & Data Science",
      slug: "analytics-data-science",
      descriptions: [
        "Retrieves, analyzes and summarizes business, operations, customer and/or economic data in order to develop business intelligence, optimize effectiveness and/or predict business outcomes",
        "Identifies, analyzes, and interprets trends or patterns and prepares ongoing reports and data visualizations (e.g., charts, dashboards, heat maps) in order to support business decisions",
        "Applies data extraction, transformation and loading techniques in order to connect large data sets from a variety of sources",
        "Creates data collection frameworks for structured and unstructured data",
        "Develops and maintains infrastructure systems (e.g., data warehouses, data lakes) including data access APIs",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "API Integration",
        "Artificial Intelligence",
        "Cloud Architectures",
        "Data Analysis",
        "Data Architecture",
        "Data Governance",
        "Data Management",
        "Data Mining",
        "Data Modeling",
        "Data Visualization",
        "Data Warehousing",
        "Predictive Analytics",
        "Vendor Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Data Analyst II"] },
          destinations: [
            {
              jobFamilySlug: "application-configuration-development",
              jobNames: ["IT Analyst II"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Data Administration",
      slug: "data-administration",
      descriptions: [
        "Administers database utilities, monitors the relationships between the database users and applications, and maintains the organization's databases across multiple platforms and computing environments",
        "Applies understanding of relational database concepts and query languages in order to design required summary or aggregation tables to support analyses",
        "Collaborates with technology/infrastructure staff to identify data relationships and functional requirements; analyzes and resolves issues related to information flow and content",
        "Maintains database support tools, database tables and dictionaries and recovery and back-up procedures",
        "Performs data modeling studies and develops detailed data models; maintains data model and entity relationship diagrams",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Data Availability",
        "Data Integrity",
        "Data Management",
        "Data Modeling",
        "Data Storage",
        "Data Warehousing",
        "Database Administration",
        "Database Design",
        "Database Management Systems",
        "Database Storage",
        "Disaster Recovery",
        "Data Availability",
        "Data Integrity",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Database Administrator"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Sr Database Administrator"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Database Administrator II"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
    {
      name: "IT Architecture",
      slug: "it-architecture",
      descriptions: [
        "Designs and develops IT architecture (integrated process, applications, data and technology) solutions to business problems in alignment with the enterprise architecture direction and standards",
        "Performs technical planning, architecture development and modification of specifications",
        "Develops specifications for new products/services, applications and service offerings",
        "Assesses the compatibility and integration of products/services proposed as standards in order to ensure an integrated architecture across interdependent technologies",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [4, 5],
          levelText: "P4 - P5",
        },
      ],
      topSkills: [
        "API Integration",
        "Application Security",
        "Architecture Strategy",
        "Artificial Intelligence",
        "Cloud Architectures",
        "Enterprise Architecture Planning",
        "Network Architecture",
        "Security Architecture Design",
        "Systems Integration",
        "Technical Documents",
        "Technology Governance",
        "API Integration",
        "Application Security",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Solution Architect"] },
          destinations: [
            {
              jobFamilySlug: "application-configuration-development",
              jobNames: ["Lead IT Analyst"],
            },
            {
              jobFamilySlug: "business-systems-analysis",
              jobNames: ["Lead Business Analyst"],
            },
            {
              jobFamilySlug: "security-and-compliance",
              jobNames: ["Lead Cybersecurity Analyst"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
      ],
    },
    {
      name: "IT Infrastructure & Edge Computing",
      slug: "it-infrastructure-edge-computing",
      descriptions: [
        "Responsibilities are within the IT Administration Function as a generalist or in a combination of Disciplines",
        "Administers the IT infrastructure within an organization, including the physical network (e.g., LANs/WANs, servers, terminals) as well as server applications and software (e.g., PeopleSoft, Oracle)",
        "Configures, installs, maintains and upgrades computer systems hardware and software",
        "Administers and maintains security of operating systems",
        "Provides operational and technical support to the organization's internal users of computer applications and hardware (e.g., PCs, servers, mainframes, printers, storage devices)",
        "Restores files or systems by designing, writing and implementing back-up procedures",
        "Answers questions regarding system procedures, online transactions, systems status and downtime procedures",
        "Collaborates with network services, software systems engineering and/or application development in order to restore service and/or identify problems",
        "Maintains a troubleshooting tracking log ensuring timely resolution of problems. Performs recovery procedures, scheduling and back-ups and monitors batch processes",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
        {
          bandShortForm: "T",
          levels: [1, 2, 3, 4],
          levelText: "T1 - T4",
        },
      ],
      topSkills: [
        "Cloud Architectures",
        "Configuration Management",
        "Data Storage",
        "Database Storage",
        "DevOps",
        "Disaster Recovery",
        "Incident Management",
        "Information Technology (IT) Administration",
        "Infrastructure as Code (IaC)",
        "Service Level Agreement (SLA)",
        "System Performance Monitoring",
        "Systems Designs",
      ],
      potentialMovements: [
        {
          source: {
            jobNames: ["Sr Infrastructure Analyst", "Sr Workstation Analyst"],
          },
          destinations: [
            {
              jobFamilySlug: "telecommunications",
              jobNames: ["Sr Network Engineer"],
            },
            {
              jobFamilySlug: "security-and-compliance",
              jobNames: [
                "Sr Cybersecurity Analyst",
                "Sr Cybr Secur Op Centr Analyst",
              ],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: {
            jobNames: ["Infrastructure Analyst II", "Workstation Analyst II"],
          },
          destinations: [
            {
              jobFamilySlug: "telecommunications",
              jobNames: ["Network Engineer II"],
            },
            {
              jobFamilySlug: "security-and-compliance",
              jobNames: [
                "Cybersecurity Analyst II",
                "Cybr Secur Op Centr Anlyst II",
                "IT Security Syst Admin II",
              ],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Telecommunications",
      slug: "telecommunications",
      descriptions: [
        "Supports network architecture consultants by preparing detailed specifications and documentation from high-level designs",
        "Provides technical expertise in the management of a public and/or private network",
        "Plans, researches, evaluates, designs and develops network systems by applying engineering, hardware and software design theories and principles to develop a compatible system infrastructure in line with organization strategies",
        "Researches networking requirements; prepares detailed architectural and installation plans",
        "Conducts internal studies of network performance and traffic analysis",
        "Researches problems, conducts cable and equipment installations, and evaluates new technologies",
        "Designs, implements and maintains the organization's voice and data communications networks",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [1, 2, 3],
          levelText: "P1 - P3",
        },
      ],
      topSkills: [
        "Access Control Management",
        "Artificial Intelligence",
        "Cloud Architectures",
        "Cloud Networking",
        "Network Administration",
        "Network Architecture",
        "Network Configurations",
        "Network Management",
        "Network Security Management",
        "Project and Program Management",
        "Requirements Management",
        "Vulnerability Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Network Engineer"] },
          destinations: [
            {
              jobFamilySlug: "it-infrastructure-edge-computing",
              jobNames: ["Sr Infrastructure Analyst", "Sr Workstation Analyst"],
            },
            {
              jobFamilySlug: "security-and-compliance",
              jobNames: [
                "Sr Cybersecurity Analyst",
                "Sr Cybr Secur Op Centr Analyst",
              ],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Network Engineer II"] },
          destinations: [
            {
              jobFamilySlug: "it-infrastructure-edge-computing",
              jobNames: ["Workstation Analyst II", "Infrastructure Analyst II"],
            },
            {
              jobFamilySlug: "security-and-compliance",
              jobNames: [
                "Cybersecurity Analyst II",
                "Cybr Secur Op Centr Anlyst II",
                "IT Security Syst Admin II",
              ],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Security and Compliance",
      slug: "security-and-compliance",
      descriptions: [
        "Evaluates, tests, monitors and maintains information systems (IS) and cyber security policies, procedures and systems",
        "Creates, implements and oversees identity management systems to meet specific security needs and compliance standards",
        "Ensures that IS and cyber security plans, controls, processes, standards, policies and procedures are aligned with IS standards",
        "Conducts security assessments of systems and applications using penetration tests, ethical hacking tools and risk assessment/mediation methodologies to evaluate vulnerabilities",
        "Identifies security exposures, determines the causes of security violations and suggests procedures to halt future incidents and improve security",
        "Designs, develops, implements and troubleshoots various information system (IS) and cyber security software",
        "Develops, tests and validates solutions to remediate exploitable conditions on devices such as web servers, mail servers, routers, firewalls and intrusion detection systems",
        "Evaluates, codes and implements software fixes (patches) to address system vulnerabilities such as malicious code (e.g., viruses), system exploitation using SQL injection, cross-site scripting, buffer overflows, parameter tampering, hidden field manipulation, cookie poisoning and web services manipulation",
        "Develops techniques and procedures for conducting IS, cyber security and access and identity risk assessments and compliance audits, the evaluation and testing of hardware, firmware and software for possible impact on system security, and the investigation and resolution of security incidents such as intrusion, frauds, attacks or leaks",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Access Control Management",
        "Application Security",
        "Cloud Networking",
        "Cybersecurity Risk Management",
        "DevSecOps",
        "Identity and Access Management (IAM)",
        "Incident Management",
        "Incident Response",
        "Information Security Management",
        "Information Technology (IT) Risk Management",
        "Intrusion Detection",
        "IT Security Management",
        "Network Penetration Testing",
        "Network Security Management",
        "Security Architecture Design",
        "Security Governance",
        "Threat Modeling",
        "Vulnerability Management",
      ],
      potentialMovements: [
        {
          source: {
            jobNames: [
              "Sr Cybersecurity Analyst",
              "Sr Cybr Secur Op Centr Analyst",
            ],
          },
          destinations: [
            {
              jobFamilySlug: "telecommunications",
              jobNames: ["Sr Network Engineer"],
            },
            {
              jobFamilySlug: "it-infrastructure-edge-computing",
              jobNames: ["Sr Infrastructure Analyst", "Sr Workstation Analyst"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: {
            jobNames: [
              "Cybersecurity Analyst II",
              "Cybr Secur Op Centr Anlyst II",
              "IT Security Syst Admin II",
            ],
          },
          destinations: [
            {
              jobFamilySlug: "telecommunications",
              jobNames: ["Network Engineer II"],
            },
            {
              jobFamilySlug: "it-infrastructure-edge-computing",
              jobNames: ["Workstation Analyst II", "Infrastructure Analyst II"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
  ],
}
