import React from "react"
import styled from "styled-components"

const DesktopContentDiv = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`

const MobileContentDiv = styled.div`
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
`

const DesktopContent = props => {
  return <DesktopContentDiv {...props}></DesktopContentDiv>
}

const MobileContent = props => {
  return <MobileContentDiv {...props}></MobileContentDiv>
}

export { DesktopContent, MobileContent }
