export default {
  name: "Real Estate & Facilities",
  slug: "real-estate-facilities",
  jobFamilies: [
    {
      name: "Facilities Management",
      slug: "facilities-management",
      descriptions: [
        "Ensures the optimal utilization of the organization's facilities",
        "Assesses and evaluates the physical space requirements of the organization and recommends plans to meet needs",
        "Ensures proper functioning of facilities through ongoing inspection and maintenance",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3],
          levelText: "P1 - P3",
        },
        {
          bandShortForm: "T",
          levels: [1, 2, 3, 4],
          levelText: "T1 - T4",
        },
      ],
      topSkills: [
        "Benchmarking",
        "Continual Improvement Process",
        "Cost Optimization",
        "Data Analysis",
        "Employee Relationship Management",
        "Energy Management",
        "Facility Management",
        "Issue Management",
        "Space Optimization",
        "Vendor Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Facilities Specialist"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Facilities Planning Specialist II"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Land/Right-of-Way",
      slug: "land-right-of-way",
      descriptions: [
        "Obtains right-of-way easements for overhead or underground use",
        "Checks property titles to determine ownership, negotiates easements and price to be paid, prepares assessments and arranges payment to grantor",
        "Perform land acquisition or divestitures",
        "Maintains permits and inspects completed work",
        "May coordinate acquisition activities with municipalities, governmental and regulatory agencies to ensure compliance with acquisition and easement requirements",
        "Manage the lifecycle of the property that we lease, own or have easement for",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Business Negotiations",
        "Conflict Management",
        "Contract Management",
        "Environmental Awareness",
        "Lease Management",
        "Property Facilities Management",
        "Real Estate Disposition",
        "Real Estate Transactions",
        "Regulatory Compliance",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Real Estate & ROW Specialist"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Sr Real Estate & ROW Specialist"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Joint Facilities Specialist II"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
  ],
}
