export default {
  name: "Quality Assurance Methods",
  slug: "quality-assurance-methods",
  jobFamilies: [
    {
      name: "Business Process Improvement",
      slug: "business-process-improvement",
      descriptions: [
        "Analyzes and measures the effectiveness of existing business processes and develops sustainable, repeatable and quantifiable business process improvements",
        "Researches best business practices within and outside the organization to establish benchmark data",
        "Collects and analyzes process data to initiate, develop and recommend business practices and procedures that focus on enhanced safety, increased productivity and reduced cost",
        "Determines how new information technologies can support reengineering business processes",
        "Responsible for benchmarking, business process analysis and reengineering, change management and measurement, and/or process-driven systems requirements",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [3, 4, 5],
          levelText: "P3 - P5",
        },
        {
          bandShortForm: "T",
          levels: [1, 2, 3, 4],
          levelText: "T1 - T4",
        },
      ],
      topSkills: [
        "Agile Methodology",
        "Business Insights",
        "Business Process Improvements",
        "Change Management",
        "Metrics Management",
        "Process Design",
        "Process Mapping",
        "Project and Program Management",
        "Quality Assurance Management",
        "Root Cause Analysis",
        "Technology Deployments",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Field Eng Process Specialist"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Sr Business Process Consultant"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
      ],
    },
  ],
}
