// TODO: set theme color

const careerBands = [
  {
    bandName: "Business Support",
    bandSlug: "business-support",
    bandShortForm: "B",
  },
  {
    bandName: "Technical Support",
    bandSlug: "technical-support",
    bandShortForm: "T",
  },
  {
    bandName: "Professional",
    bandSlug: "professional",
    bandShortForm: "P",
  },
  {
    bandName: "Leadership",
    bandSlug: "leadership",
    bandShortForm: "L",
  },
]

export default careerBands
