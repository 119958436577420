export default {
  name: "Administrative Services",
  slug: "administrative-services",
  jobFamilies: [
    {
      name: "Administrative Services Generalist/Multidiscipline",
      slug: "administrative-services-generalist-multidiscipline",
      descriptions: [
        "Supports general business operations by providing various administrative support activities as a generalist or in a combination of Disciplines in the Administrative Services Function",
        "Provides administrative support to employees in the organization",
        "Uses business software applications (e.g., word processing, presentation and spreadsheet) to prepare correspondence, reports, presentations, agendas, minutes, etc.",
        "Receives, screens and directs incoming calls, visitors, mail and email",
        "Maintains files, records, and calendars",
        "May arrange business travel, coordinate meeting arrangements, and/or track expenses",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [3],
          levelText: "P3",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Business Process Improvements",
        "Communication Management",
        "Customer Experience Management",
        "Data Analysis",
        "Data Preparation",
        "Knowledge Management",
        "Prioritization",
        "Records Management",
        "Scheduling Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Corporate Travel Coordinator"] },
          destinations: [
            {
              jobFamilySlug: "risk-management-generalist-multidiscipline",
              jobNames: [],
            },
            { jobFamilySlug: "claims-resolution", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "B",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Administrative Assistant II"] },
          destinations: [
            {
              jobFamilySlug: "risk-management-generalist-multidiscipline",
              jobNames: [],
            },
            { jobFamilySlug: "claims-resolution", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "B",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Business Continuation",
      slug: "Business Continuation",
      descriptions: [
        "Develops and implements plans and practices to achieve efficient and effective communication and restoration of operations during emergencies",
        "Conducts assessments to identify gaps in business continuity, emergency and disaster recovery plans",
        "Develops and tests infrastructure protection strategies and incident response exercises",
        "Coordinates disaster recovery initiatives and plans with staff and line functions",
        "Develops and coordinates prevention and emergency preparation plans with government safety and security agencies (e.g., police, fire, military)",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [4, 5],
          levelText: "P4 - P5",
        },
      ],
      topSkills: [
        "Business Process Improvements",
        "Business Continuity Planning",
        "Change Management",
        "Crisis Management",
        "Emergency Response",
        "Risk Analytics",
        "Stakeholder Management",
        "Vulnerability Scanning",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Emerg Prep & Response Specialist"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
      ],
    },
    {
      name: "Business Security",
      slug: "business-security",
      descriptions: [
        "Responsibilities are within the Security Function as a generalist or in a combination of disciplines",
        "Protects the organization's employees, properties and all items of value on premises from any preventable harm or danger",
        "Develops security policies and procedures that comply with government guidelines and standards",
        "Conducts investigations to protect organization assets",
        "Responds to contingency events, including bomb threats, sabotage and severe weather conditions through on-site security force or with the assistance of government law enforcement agencies",
        "Recommends hiring of outside security contractors as necessary and may oversee contract guard force",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Business Process Improvements",
        "Asset Protection",
        "Critical Incident Training",
        "Physical Security Management",
        "Regulatory Compliance",
        "Scenario Planning",
        "Security Governance",
        "Security Management",
        "Stakeholder Management",
        "Vendor Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Physical Security Specialist"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Lead Physical Security Specialist"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
      ],
    },
    {
      name: "Vehicle Fleet Management",
      slug: "vehicle-fleet-management",
      descriptions: [
        "Operates and maintains motor vehicles and equipment",
        "Schedules and dispatches the organization's vehicles and drivers",
        "Prepares reports on inspection findings and ensures proper vehicle maintenance to comply with prescribed safety regulations",
        "Develops design specifications for vehicle requisitions",
        "May negotiate vehicle and/or equipment purchase terms in coordination with the procurement function",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2],
          levelText: "P1 - P2",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Business Process Improvements",
        "Records Management",
        "Fleet Maintenance Management",
        "Fleet Management",
        "Fleet Management Systems",
        "Fleet Optimization",
        "Regulatory Compliance",
        "Vehicle Leasing",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Fleet Administration Specialist II"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
  ],
}
