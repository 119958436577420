export default {
  name: "Energy Trading",
  slug: "energy-trading",
  jobFamilies: [
    {
      name: "Fossil Fuel Purchasing",
      slug: "fossil-fuel-purchasing",
      descriptions: [
        "Develops and implements procurement plans to support forecasted fossil fuel needs and objectives",
        "Establishes and implements strategies for negotiating fossil fuel purchase agreements based on in-depth knowledge of foreign and domestic fossil fuel markets for optimum pricing and terms",
        "Employs knowledge of fuel qualities, plant utilization constraints, transportation options, risk management and procurement evaluation models to develop and implement optimal procurement strategies",
        "Contributes expertise in the areas of fossil fuel markets to other fuel-related projects and to the development of current short- and long-term price forecasts and fuel trading",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [3],
          levelText: "P3",
        },
      ],
      topSkills: [
        "Business Negotiations",
        "Cost Optimization",
        "Energy Management",
        "Portfolio Optimization",
        "Power Scheduling",
        "Pricing Strategies",
        "Procurement Management",
        "Supplier Evaluations",
        "Supplier Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Fuel Supply Specialist"] },
          destinations: [
            {
              jobFamilySlug:
                "energy-marketing-and-trading-generalist-multidiscipline",
              jobNames: ["Sr. Power Trading Specialist"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Sr. Transportation Ops Consultant"] },
          destinations: [
            {
              jobFamilySlug:
                "energy-marketing-and-trading-generalist-multidiscipline",
              jobNames: ["Sr. Power Trading Specialist"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
      ],
    },
    {
      name: "Gas Supply",
      slug: "gas-supply",
      descriptions: [
        "Plans and secures adequate supplies of natural gas to meet the organization's needs",
        "Develops long-term supply, which may include transportation and storage arrangements",
        "Negotiates gas purchase contracts with suppliers and, as required, with transportation organizations and storage operators | Develops forecasts of needed supply, transportation requirements, and available storage",
        "Manages relationships with suppliers",
        "Administers and monitors existing purchase and sale contracts within prescribed legal policies and procedures and works closely with other groups to determine pricing and terms of new contracts",
        "Identifies new long-term supply opportunities, and, working with the energy marketing and trading group, assists in determining prices and contract terms for profitable transactions",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [4, 5],
          levelText: "P4 - P5",
        },
      ],
      topSkills: [
        "Business Negotiations",
        "Cost Optimization",
        "Data Modeling",
        "Energy Management",
        "Power Scheduling",
        "Pricing Strategies",
        "Procurement Strategies",
        "Risk Analytics",
        "Supplier Management",
        "Sustainable Supply Chains",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Gas Trading Specialist"] },
          destinations: [
            {
              jobFamilySlug:
                "energy-marketing-and-trading-generalist-multidiscipline",
              jobNames: ["TL Energy Trading"],
            },
            {
              jobFamilySlug:
                "energy-trading-and-scheduling-generalist-multidiscipline",
              jobNames: [
                "TL Energy Scheduling & Analytics",
                "TL Gen Mrkts and ST Trading",
              ],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
      ],
    },
    {
      name: "Energy Marketing and Trading Generalist/Multidiscipline",
      slug: "energy-marketing-and-trading-generalist-multidiscipline",
      descriptions: [
        "Purchases and sells power/energy directly to/from end users and intermediaries under negotiated rates and terms, and trades energy",
        "Takes responsibility for transaction structuring and negotiation, risk management and trading of energy, and distribution and/or interconnection and transmission of energy through other organizations' transmission and distribution systems",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [3],
          levelText: "P3",
        },
      ],
      topSkills: [
        "Business Negotiations",
        "Cost Optimization",
        "Energy Settlement",
        "Energy Trading",
        "Energy Management",
        "Financial Risk Management",
        "Hedging Strategies",
        "Positioning Strategies",
        "Power Market Analysis",
        "Regulatory Compliance",
        "Risk Analytics",
        "Risk Management",
        "Transaction Structuring",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["TL Energy Trading"] },
          destinations: [
            {
              jobFamilySlug:
                "energy-trading-and-scheduling-generalist-multidiscipline",
              jobNames: [
                "TL Enrgy Scheduling & Analytic",
                "TL Gen Mrkts and ST Trading",
              ],
            },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 1,
          },
        },
        {
          source: { jobNames: ["Sr Power Trading Specialist"] },
          destinations: [
            {
              jobFamilySlug: "gas-supply",
              jobNames: ["Lead Gas Trading Specialist"],
            },
            {
              jobFamilySlug: "wholesale-market-functions",
              jobNames: [
                "Lead Generation Mkts Consultant",
                "Lead Energy Mkts Consultant",
              ],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
      ],
    },
    {
      name: "Wholesale Market Functions",
      slug: "wholesale-market-functions",
      descriptions: [
        "Develops and maintains positive relationships with market participants on behalf of the Independent System Operator/Regional Transmission Organization (ISO/RTO)",
        "Receives and processes customer inquiries and resolves day-to-day operating issues spanning system operations, market operations, transaction scheduling and transmission tariff administration on behalf of market participants",
        "Develops and maintains understanding of grid operations and wholesale market processes, procedures, protocols and rules, and market participants' business operations to support market participant's business needs",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [4, 5],
          levelText: "P4 - P5",
        },
      ],
      topSkills: [
        "Cost Optimization",
        "Data Modeling",
        "Energy Policy",
        "Energy Management",
        "Market Analysis",
        "Policy Analysis",
        "Portfolio Optimization",
        "Regulatory Compliance",
        "Relationship Management",
        "Simulation Models",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Generation Mkts Consultant"] },
          destinations: [
            {
              jobFamilySlug:
                "energy-trading-and-scheduling-generalist-multidiscipline",
              jobNames: [
                "TL Enrgy Scheduling & Analytic",
                "TL Gen Mrkts and ST Trading",
              ],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Lead Energy Markets Consultant"] },
          destinations: [
            {
              jobFamilySlug:
                "energy-trading-and-scheduling-generalist-multidiscipline",
              jobNames: [
                "TL Enrgy Scheduling & Analytic",
                "TL Gen Mrkts and ST Trading",
              ],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
      ],
    },
    {
      name: "Energy Trading Scheduling Generalist/Multidiscipline",
      slug: "energy-trading-and-scheduling-generalist-multidiscipline",
      descriptions: [
        "Nominates and schedules energy commodity purchases and sales on the 24-hour desk",
        "Tracks relevant data on the appropriate systems",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3],
          levelText: "P1 - P3",
        },
      ],
      topSkills: [
        "Cost Optimization",
        "Data Analysis",
        "Data Management",
        "Demand Planning",
        "Energy Purchasing",
        "Energy Settlement",
        "Energy Management",
        "Portfolio Optimization",
        "Power Scheduling",
        "Power Transaction Tagging",
        "Regulatory Compliance",
      ],
      potentialMovements: [
        {
          source: {
            jobNames: [
              "TL Enrgy Scheduling & Analytic",
              "TL Gen Mrkts and ST Trading",
            ],
          },
          destinations: [
            {
              jobFamilySlug:
                "energy-marketing-and-trading-generalist-multidiscipline",
              jobNames: ["TL Energy Trading"],
            },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 1,
          },
        },
        {
          source: {
            jobNames: [
              "Sr Energy Market Analyst",
              "Sr Energy Market Specialist",
            ],
          },
          destinations: [
            {
              jobFamilySlug:
                "energy-marketing-and-trading-generalist-multidiscipline",
              jobNames: ["Sr Power Trading Specialist"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
      ],
    },
  ],
}
