/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import styled from "styled-components"
import MenuIcon from "@material-ui/icons/Menu"
import Dialog from "@material-ui/core/Dialog"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"

import theme from "@assets/styles/theme"

import logo from "@assets/logo.svg"
import UnstyledLink from "@components/elements/UnstyledLink"
import MobileDivider from "@components/elements/MobileDivider"
import MobileMenuAccordion from "@components/modules/MobileMenuAccordion"
import SearchTypeahead from "@components/modules/SearchTypeahead"

import { MOBILE_MENU } from "@constants/menu"
import JOB_FAMILY_GROUPS from "@content/job-family-groups"
import { getJobFamilyUrl } from "@helpers/url"

const JFG = JOB_FAMILY_GROUPS.filter(g => !!g)

const Menu = styled.div`
  position: sticky;
  top: 0;

  height: var(--mobile-menu-height);
  display: flex;

  background: ${props => props.theme.colors.grey.white};

  font-weight: bold;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  user-select: none;

  border-bottom: ${props => `solid 1px ${props.theme.colors.grey.grey}`};

  z-index: 3;

  > *:last-child {
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    display: none;
  }

  &.mobile-inner {
    padding: 7px 16px 4px 16px;
    background-color: #ffffff;
  }
`

const MenuLink = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: ${({ margin }) => margin || "0px"};
  cursor: pointer;
  padding: 0.825rem 8px;
  user-select: none;

  > a {
    color: #2a2a2a;
  }
`

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

const MenuModal = styled(Dialog)`
  @media (min-width: 1024px) {
    display: none;
  }
`

const ModalContent = styled.div`
  height: 100%;
  background: white;
  padding: 12px;
  font-size: 12px;
  color: #2a2a2a;
`

const SubTrackContainer = styled.div`
  background: ${theme.colors.primary.blueLightest};
  border-top: 2px solid ${theme.colors.primary.blue};
  padding: 8px 0;

  a {
    display: block;
    padding: 8px 16px;
    color: #2a2a2a;
  }
`

const SearchWrapper = styled.div`
  width: 100%;
  padding: 0 0 12px 0;
`

const MobileMenu = () => {
  const [isMenuOpen, setMenuOpen] = React.useState(false)
  const [activeAccordionIndex, setActiveAccordionIndex] = React.useState(-1)
  const [searching, setSearching] = React.useState(false)

  const handleClose = () => setMenuOpen(false)

  return (
    <>
      <Menu>
        <UnstyledLink to="/">
          <img
            src={logo}
            width="53px"
            height="auto"
            alt="Career Visualization Tool"
          />
        </UnstyledLink>
        <div onClick={() => setMenuOpen(true)}>
          <MenuIcon style={{ color: theme.colors.grey.black }} />
        </div>
      </Menu>
      <MenuModal
        fullScreen
        open={isMenuOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Menu isOpen={isMenuOpen} className="mobile-inner">
          <UnstyledLink to="/">
            <img
              src={logo}
              width="53px"
              height="auto"
              alt="Career Visualization Tool"
            />
          </UnstyledLink>
          <div onClick={handleClose}>
            <CloseIcon style={{ color: theme.colors.grey.black }} />
          </div>
        </Menu>
        <ModalContent>
          <SearchWrapper>
            <SearchTypeahead
              small={true}
              mobileNav={true}
              style={{ width: "100%" }}
              onOpenSearch={() => setSearching(true)}
              onCloseSearch={() => setSearching(false)}
            />
          </SearchWrapper>
          {MOBILE_MENU.map((menuItem, index) => (
            <MenuLink key={`mobile-menu-first-${index}`}>
              <UnstyledLink to={menuItem.to} onClick={() => handleClose()}>
                {menuItem.name}
              </UnstyledLink>
            </MenuLink>
          ))}

          <MobileDivider margin="8px 0px" />
          {!searching && (
            <>
              <MenuLink style={{ fontSize: "12px", fontWeight: 100 }}>
                <UnstyledLink to="" onClick={() => handleClose()}>
                  Job Family Groups
                </UnstyledLink>
              </MenuLink>

              {JFG.map((group, index) => (
                <MobileMenuAccordion
                  header={group.name}
                  index={index}
                  active={activeAccordionIndex === index}
                  setActiveIndex={() => setActiveAccordionIndex(index)}
                  color={group.boxColor}
                  key={index}
                  hasSubTrack={group.jobFamilies.length > 0}
                >
                  <SubTrackContainer>
                    {group.jobFamilies.map((jf, jfIndex) => {
                      return (
                        <UnstyledLink
                          to={getJobFamilyUrl(group.slug, jf.slug)}
                          onClick={() => handleClose()}
                          key={`accordion-${index}-${jfIndex}`}
                        >
                          {jf.name}
                        </UnstyledLink>
                      )
                    })}
                  </SubTrackContainer>
                </MobileMenuAccordion>
              ))}
            </>
          )}
        </ModalContent>
      </MenuModal>
    </>
  )
}

export default MobileMenu
