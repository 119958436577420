export default {
  name: "Marketing",
  slug: "marketing",
  jobFamilies: [
    {
      name: "Market Research/Intelligence",
      slug: "market-research-intelligence",
      descriptions: [
        "Performs analyses and prepares forecasts and recommendations in the areas of product preferences, sales coverage, market penetration, market practices and sales trends",
        "Researches market conditions to determine potential sales of a product",
        "Collects, analyzes and uses quantitative and qualitative consumer data to develop insights into consumer preferences, behaviors, patterns and trends and understand perception and drivers of consumer/market dynamics",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [1, 2],
          levelText: "P1 - P2",
        },
      ],
      topSkills: [
        "Data Analysis",
        "Business Intelligence Reporting",
        "Data Integration",
        "Data Interpretation",
        "Data Visualization",
        "Market Analysis",
        "Market Research",
        "Statistical Data Modeling",
        "Strategic Insights",
        "Trend Analysis",
        "Trend Forecasting",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Strategic Planning Analyst II"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Creative Services",
      slug: "creative-services",
      descriptions: [
        "Develops creative, clear material in support of the organization's communications strategies",
        "Plans, develops, and publishes internal or external communications (e.g., newsletters, brochures, manuals, website, video content)",
        "Develops and maintains the organization's style guide, including editorial standards and policies as well as maintains and properly stores the organization’s content archive",
        "Understands and connects with target audience",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [1, 2, 3],
          levelText: "P1 - P3",
        },
      ],
      topSkills: [
        "Audience Analysis",
        "Content Creation",
        "Marketing Automation",
        "Marketing Campaigns",
        "Content Writing",
        "Data Visualization",
        "Graphic Design",
        "Photography",
        "Storytelling",
        "Value Propositions",
        "Video Production",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Graphic Arts Specialist"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Marketing Strategy Consultant II"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Digital Graphic Design Specialist I"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Marketing Generalist",
      slug: "marketing-generalist",
      descriptions: [
        "Responsibilities are within the Marketing Function as a generalist or in a combination of Disciplines",
        "Designs, develops and implements data-driven marketing programs and/or pricing strategies to support the organization's products, services or market sector",
        "Designs, develops and coordinates marketing programs for various service offerings",
        "Uses specific data-based marketing strategies and media (e.g., print, broadcast, digital) to launch and position products and services in a sector",
        "Identifies and implements marketing strategies and programs in collaboration with sales and technical teams",
        "Coordinates the development of advertising/marketing communications materials by creative design and creative writing services to effectively represent the products, services, brands and/or the organization to customers and prospects",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [3],
          levelText: "P3",
        },
        {
          bandShortForm: "T",
          levels: [1, 2, 3, 4],
          levelText: "T1 - T4",
        },
      ],
      topSkills: [
        "Audience Analysis",
        "Brand Strategy",
        "Content Creation",
        "Customer Experience Management",
        "Data Analysis",
        "Digital Marketing",
        "Market Messaging",
        "Marketing Automation",
        "Marketing Campaigns",
        "Pricing Strategies",
        "Product Marketing",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["TL Digital & Creative", "TL Marketing"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
  ],
}
