import React from "react"
import styled from "styled-components"
import theme from "@assets/styles/theme"
import Collapse from "@kunukn/react-collapse"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"

const AccordionWrapper = styled.div`
  width: auto;
  color: ${props => (props.active ? `${props.color}` : "#2a2a2a")};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 0.825rem 8px;

  border-bottom: ${props =>
    props.active ? `solid 2px ${props.color}` : "none"};
`

const Title = styled.div`
  flex-grow: 1;

  &.active {
    color: ${theme.colors.primary.blue};
  }

  & + .mobile-accordion-icon {
    color: ${theme.colors.primary.blue};
  }
`

const collapseStyle = {
  background: "rgba(242, 242, 242, 0.5)",
}

const Accordion = ({
  children,
  color,
  index,
  header,
  active,
  setActiveIndex,
  hasSubTrack,
}) => {
  const [isOpen, setIsOpen] = React.useState(active)

  React.useEffect(() => {
    setIsOpen(active)
  }, [active])

  const handleAccordionHeaderClick = () => {
    if (isOpen) {
      setActiveIndex(-1)
      setIsOpen(false)
    } else {
      setActiveIndex(index)
      setIsOpen(true)
    }
  }

  return (
    <>
      <AccordionWrapper
        onClick={handleAccordionHeaderClick}
        active={isOpen}
        color={color}
      >
        <Header active={isOpen} color={color}>
          <Title className={isOpen ? "active" : ""}>{header}</Title>
          {hasSubTrack ? (
            isOpen ? (
              <KeyboardArrowDownIcon className="mobile-accordion-icon" />
            ) : (
              <ChevronRightIcon className="mobile-accordion-icon" />
            )
          ) : (
            ""
          )}
        </Header>
      </AccordionWrapper>
      <Collapse
        style={collapseStyle}
        isOpen={isOpen}
        transition="height 0.2s cubic-bezier(.4, 0, .2, 1)"
      >
        {children}
      </Collapse>
    </>
  )
}

export default Accordion
