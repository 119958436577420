module.exports = {
  MOBILE_MENU: [
    {
      name: "How to Use",
      to: "/user-guide",
    },
    {
      name: "Career Bands & Levels",
      to: "/career-bands",
    },
  ],
}
