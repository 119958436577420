export default {
  name: "Account/Relationship Management",
  slug: "account-relationship-management",
  jobFamilies: [
    {
      name: "Account/Relationship Management Generalist/Multidiscipline",
      slug: "account-relationship-management-generalist-multidiscipline",
      descriptions: [
        "Responsibilities are within the Account/Relationship Management Function as a generalist or in a combination of Disciplines",
        "Builds and maintains effective relationships and a high level of satisfaction with an assigned group of customer accounts that may include major strategic customers within a geographic or industry focus",
        "Identifies, develops and potentially closes new sales opportunities",
        "Creates demand for the organization's products and services by raising their profile with customers",
        "Achieves revenue targets by increasing revenue spend per account",
        "May conduct regular status and strategy meetings with the customer's senior management to understand their needs and link them to the organization's product/service strategies",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 4, 5],
          levelText: "P1 - P2, P4 - P5",
        },
      ],
      topSkills: [
        "Account Planning",
        "Business Development",
        "Business Negotiations",
        "Competitive Intelligence",
        "Contract Management",
        "Customer Experience Management",
        "Customer Journey Mapping",
        "Customer Needs",
        "Customer Success Management",
        "Data Analysis",
        "Sales",
        "Sales Forecasting",
        "Segmentation Strategy",
        "Solution Selling",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Mgr Strategic Alliance"] },
          destinations: [{ jobFamilySlug: "key-accounts", jobNames: [] }],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Lead Strategic Alliance Consultant"] },
          destinations: [{ jobFamilySlug: "key-accounts", jobNames: [] }],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Comm & Bus Develop Specialist II"] },
          destinations: [{ jobFamilySlug: "key-accounts", jobNames: [] }],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Strategic Alliance Specialist I"] },
          destinations: [{ jobFamilySlug: "key-accounts", jobNames: [] }],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Economic/Market Development",
      slug: "economic-market-development",
      descriptions: [
        "Formulates, plans and implements community and business development programs to improve the economies of the organization's service territories by attracting, expanding and retaining industries",
        "Works with community and government leaders to develop programs that attract new industry and expand existing industry within the service territory",
        "Responds to business development inquiries by prospective organizations and institutions with technical data presentations on the area's industrial facilities and community infrastructure resources",
        "Meets with domestic and international prospects to encourage locating within the service area and presents potential site locations and rate packages",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [3, 4, 5],
          levelText: "P3 - P5",
        },
      ],
      topSkills: [
        "Account Planning",
        "Business Development",
        "Business Negotiations",
        "Competitive Intelligence",
        "Contract Management",
        "Customer Experience Management",
        "Customer Needs",
        "Sales",
        "Solution Selling",
        "Go-To-Market Strategies",
        "Project and Program Management",
        "Sales Pipeline Management",
        "Stakeholder Management",
        "Value Propositions",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Mgr Business Support & Develop"] },
          destinations: [{ jobFamilySlug: "key-accounts", jobNames: [] }],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Lead Community Development Specialist"] },
          destinations: [
            {
              jobFamilySlug:
                "account-relationship-management-generalist-multidiscipline",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Sr Comm & Economic Dev Consultant"] },
          destinations: [
            {
              jobFamilySlug:
                "account-relationship-management-generalist-multidiscipline",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
      ],
    },
    {
      name: "Key Accounts",
      slug: "key-accounts",
      descriptions: [
        "Manages accounts of customers with substantial energy usage such as commercial or industrial consumers, universities and local municipalities",
        "Identifies, develops and potentially closes new sales opportunities",
        "Works with local Economic and Community Development organizations to foster growth and development of our communities",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Business Development",
        "Business Negotiations",
        "Competitive Intelligence",
        "Customer Needs",
        "Data Analysis",
        "Community Outreach",
        "Company Representation",
        "Economic Development Strategy",
        "Innovation Management",
        "Partnership Management / Partnership Development",
        "Pricing Strategies",
        "Project and Program Management",
        "Business Development",
        "Business Negotiations",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Key Account Specialist"] },
          destinations: [
            {
              jobFamilySlug:
                "account-relationship-management-generalist-multidiscipline",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Sr Key Account Specialist"] },
          destinations: [
            {
              jobFamilySlug:
                "account-relationship-management-generalist-multidiscipline",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Key Account Specialist II"] },
          destinations: [
            {
              jobFamilySlug:
                "account-relationship-management-generalist-multidiscipline",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Key Account Specialist I"] },
          destinations: [
            {
              jobFamilySlug:
                "account-relationship-management-generalist-multidiscipline",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
  ],
}
