import ACCOUNT_RELATIONSHIP_MANAGEMENT from "./job-family-group/account-relationship-management"
import ADMINISTRATIVE_SERVICES from "./job-family-group/administrative-services"
import AUDIT_FINANCIAL_BUSINESS_CONTROLS from "./job-family-group/audit-financial-business-controls"
import CORPORATE_AFFAIRS from "./job-family-group/corporate-affairs"
import CUSTOMER_SOLUTIONS_AND_PRODUCTS from "./job-family-group/customer-solutions-and-products"
import CUSTOMER_SUPPORT_OPERATIONS from "./job-family-group/customer-support-operations"
import ENERGY_DELIVERY_DISTRIBUTION from "./job-family-group/energy-delivery-distribution"
import ENERGY_TRADING from "./job-family-group/energy-trading"
import ENGINEERING_AND_TECHNICAL_SPECIALTY from "./job-family-group/engineering-and-technical-specialty"
import ENVIRONMENTAL_AND_SUSTAINABILITY from "./job-family-group/environmental-and-sustainability"
import ENVIRONMENTAL_HEALTH_SAFETY from "./job-family-group/environmental-health-safety"
import FINANCE_ACCOUNTING from "./job-family-group/finance-accounting"
import GENERATION from "./job-family-group/generation"
import HUMAN_RESOURCES from "./job-family-group/human-resources"
import INFORMATION_TECHNOLOGY from "./job-family-group/information-technology"
import LEGAL from "./job-family-group/legal"
import MARKETING from "./job-family-group/marketing"
import PROJECT_PROGRAM_MANAGEMENT from "./job-family-group/project-program-management"
import QUALITY_ASSURANCE_METHODS from "./job-family-group/quality-assurance-methods"
import REAL_ESTATE_FACILITIES from "./job-family-group/real-estate-facilities"
import REGULATORY_BUSINESS_STRATEGY from "./job-family-group/regulatory-business-strategy"
import SUPPLY_CHAIN from "./job-family-group/supply-chain"

// Ordering them in the specific sequence requested by the client
export default [
  ACCOUNT_RELATIONSHIP_MANAGEMENT,
  ADMINISTRATIVE_SERVICES,
  ENERGY_DELIVERY_DISTRIBUTION,
  CUSTOMER_SOLUTIONS_AND_PRODUCTS,
  AUDIT_FINANCIAL_BUSINESS_CONTROLS,
  ENERGY_TRADING,
  CUSTOMER_SUPPORT_OPERATIONS,
  CORPORATE_AFFAIRS,
  ENGINEERING_AND_TECHNICAL_SPECIALTY,
  MARKETING,
  FINANCE_ACCOUNTING,
  ENVIRONMENTAL_AND_SUSTAINABILITY,
  PROJECT_PROGRAM_MANAGEMENT,
  HUMAN_RESOURCES,
  ENVIRONMENTAL_HEALTH_SAFETY,
  REAL_ESTATE_FACILITIES,
  INFORMATION_TECHNOLOGY,
  GENERATION,
  REGULATORY_BUSINESS_STRATEGY,
  LEGAL,
  QUALITY_ASSURANCE_METHODS,
  "",
  "",
  SUPPLY_CHAIN,
]
