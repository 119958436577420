export default {
  name: "Environmental Health & Safety",
  slug: "environmental-health-safety",
  jobFamilies: [
    {
      name: "Workers' Compensation Case Management",
      slug: "workers-compensation-case-management",
      descriptions: [
        "Directs and coordinates appropriate, timely and cost-effective delivery of health care related to disability, urgent and emergency care, work limitations, transitional return to work plans, accommodations and part-time work for workers' compensation and nonworkers' compensation illness and injury",
        "Determines service needs, selects and evaluates the services of appropriate network providers, and makes recommendations for retention or removal from the network",
        "Identifies and coordinates the process of cases requiring physician-level review and implements recommendations",
        "Identifies cases involving high-frequency and high-risk injuries/illnesses and performs ongoing evaluation and treatment plans",
        "Determines essential job functions and identifies and implements necessary limitations, accommodations and part-time work for high-frequency/high-risk cases to reduce the length of disability",
      ],
      jobLevels: [],
      topSkills: [
        "Health, Safety and Environmental (HSE) Management",
        "Regulatory Compliance",
        "Stakeholder Management",
        "Case Management",
        "Claims Adjudication",
        "Data Analysis",
        "Data Management",
        "Employee Relationship Management",
        "Investigation Management",
        "Records Management",
        "Risk Management",
      ],
      potentialMovements: [
        // No jobs currently mapped to this job family
        {
          source: { jobNames: [] },
          destinations: [
            {
              jobFamilySlug: "risk-management-generalist-multidiscipline",
              jobNames: [],
            },
          ],
          jobLevel: null,
        },
      ],
    },
    {
      name: "Environmental Health and Safety Generalist/Multidiscipline",
      slug: "environmental-health-and-safety-generalist-multidiscipline",
      descriptions: [
        "Responsibilities are within the Environmental Health and Safety Function as a generalist or in a combination of Disciplines",
        "Designs, develops, implements and oversees the organization's environmental, health and safety programs and procedures to safeguard employees and surrounding communities and to ensure that all facilities are in compliance with regulations",
        "Develops and implements programs to ensure that regulatory obligations are fulfilled in a cost-effective manner and that risks are effectively managed",
        "Investigates accidents and promotes safety-conscious work performance and training programs",
        "Interacts with field employees and daily activities",
        "Deploys new programs and procedures for implementation success",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Auditing",
        "Behavioral Based Safety",
        "Change Management",
        "Environmental Health and Safety (EHS) Risk Management",
        "Health, Safety and Environmental (HSE) Management",
        "Incident Management",
        "Internal Controls",
        "Regulatory Compliance",
        "Stakeholder Management",
        "Standards Compliance",
        "Training People",
        "Vulnerability Scanning",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Environmental & Safety Specialist"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Environmental & Safety Specialist II"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Genco Safety Specialist I"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
  ],
}
