export default {
  name: "Energy Delivery/Distribution",
  slug: "energy-delivery-distribution",
  jobFamilies: [
    {
      name: "Electric / Gas Distribution Construction/Operations/Maintenance",
      slug: "electric-gas-distribution-construction-operations-maintenance",
      descriptions: [
        "Constructs, removes, operates and maintains electric distribution facilities and equipment, including cable, transformers, breakers and other associated devices",
        "Constructs, removes, operates and maintains gas distribution facilities and equipment, including pipe, gauges and valves",
        "May be responsible for sub transmission facilities and field engineering and/or operating activities",
        "Coordinates, schedules and supervises crews engaged in the construction, removal and maintenance of electric / gas distribution facilities and equipment",
        "Dispatches field crews, troubleshooters and testers engaged in the construction, maintenance and emergency restoration of electric / gas distribution facilities and customers' service facilities",
        "Responsibilities may be limited to an assigned district or area",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3],
          levelText: "P1 - P3",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Construction Management",
        "Corrective Maintenance",
        "Customer Service Management",
        "Electrical Distribution Systems",
        "Emergency Response",
        "Gas Distribution; Gas Systems",
        "Issue Management",
        "Maintenance Management",
        "Occupational Safety & Health",
        "Outage Management",
        "Preventative Maintenance",
        "Standards Compliance",
        "Troubleshooting",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Mgr Customer Operations"] },
          destinations: [
            {
              jobFamilySlug:
                "energy-delivery-distribution-generalist-multidiscipline",
              jobNames: ["Sr Operations"],
            },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Mgr Customer Ops Construction"] },
          destinations: [
            {
              jobFamilySlug: "electric-gas-distribution-systems-operations",
              jobNames: ["Mgr Distrib System"],
            },
            {
              jobFamilySlug:
                "electric-distribution-meter-services-generalist-multidiscipline",
              jobNames: ["Mgr Elec Metering Services"],
            },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Work Order Support Coordinator II"] },
          destinations: [
            {
              jobFamilySlug: "vegetation-management-generalist-multidiscipline",
              jobNames: ["Line Clearance Coordinator II"],
            },
          ],
          jobLevel: {
            bandShortForm: "B",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Electric / Gas Distribution Quality Control / Inspection",
      slug: "electric-gas-distribution-quality-control-inspection",
      descriptions: [
        "Constructs, removes, operates and maintains electric distribution facilities and equipment, including cable, transformers, breakers and other associated devices",
        "Constructs, removes, operates and maintains gas distribution facilities and equipment, including pipe, gauges and valves",
        "May be responsible for sub transmission facilities and field engineering and/or operating activities",
        "Coordinates, schedules and supervises crews engaged in the construction, removal and maintenance of electric / gas distribution facilities and equipment",
        "Dispatches field crews, troubleshooters and testers engaged in the construction, maintenance and emergency restoration of electric / gas distribution facilities and customers' service facilities",
        "Responsibilities may be limited to an assigned district or area",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [1, 2],
          levelText: "P1 - P2",
        },
        {
          bandShortForm: "T",
          levels: [1, 2, 3, 4],
          levelText: "T1 - T4",
        },
      ],
      topSkills: [
        "Data Analysis",
        "Destructive Testing",
        "Maintenance Inspections; Site Inspections; Mechanical Inspections",
        "Nondestructive Evaluation (NDE)",
        "Occupational Safety & Health",
        "Process Design",
        "Quality Auditing",
        "Quality Evaluation",
        "Records Management",
        "Regulatory Compliance",
        "Standards Compliance",
        "Technical Reports",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Gas Compliance Project Manager"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
      ],
    },
    {
      name: "Electric / Gas Distribution Systems Operations",
      slug: "electric-gas-distribution-systems-operations",
      descriptions: [
        "Operates electric and/or gas power distribution system to ensure adequate distribution of electricity/gas to meet power demands",
        "Calculates load estimates according to corrected weather and consumer-demand records and notifies power supply function of electric/gas power required to meet fluctuating demands",
        "Monitors control board showing operating condition of lines and equipment throughout the system and makes adjustments or directs workers to make adjustments as conditions warrant",
        "Directs substations to de-energize malfunctioning circuits or adjusts switches to ensure safety of maintenance crew and avoid interruptions of service during repairs",
        "Coordinates, schedules and supervises crews engaged in the construction, removal and maintenance of electric / gas distribution facilities and equipment",
        "Notifies maintenance crew of location and de-energization of troubled sector and verifies that workers are clear of repaired equipment before directing energization of circuit",
        "Dispatches field crews, troubleshooters and testers engaged in the customer complaint resolution and emergency restoration of electric / gas distribution facilities and customers' service facilities",
        "May operate computer console programmed to automatically perform load-control functions",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [3],
          levelText: "P3",
        },
        {
          bandShortForm: "T",
          levels: [1, 2, 3, 4],
          levelText: "T1 - T4",
        },
      ],
      topSkills: [
        "Data Analysis",
        "Demand Planning",
        "Distribution Management; Distribution Control Systems",
        "Emergency Response",
        "Occupational Safety and Health; Health, Safety and Environmental (HSE) Management",
        "Load Calculations; Load Planning; Load Forecasting",
        "Operations Management",
        "Outage Management",
        "Power Distribution System; Electrical Distribution Systems; Electrical Power Distribution",
        "Power Monitoring",
        "Power Systems Control",
        "Circuit Switching; Switching Power Supplies",
        "Utility Mapping",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Mgr Generation System Ops"] },
          destinations: [
            {
              jobFamilySlug:
                "electric-gas-distribution-construction-operations-maintenance",
              jobNames: ["Mgr Customer Ops Construction"],
            },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Mgr Generation System Ops"] },
          destinations: [
            {
              jobFamilySlug:
                "energy-delivery-distribution-generalist-multidiscipline",
              jobNames: ["TL Customer Operations"],
            },
            {
              jobFamilySlug: "vegetation-management-generalist-multidiscipline",
              jobNames: ["TL Line Clearance and Inspection"],
            },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Electric Distribution Meter Services Generalist/Multidiscipline",
      slug: "electric-distribution-meter-services-generalist-multidiscipline",
      descriptions: [
        "Reads electric meters and records usage data (manually or electronically)",
        "Tests, repairs and maintains stocks of metering equipment and meter testing equipment, including smart meters",
        "Installs, removes, field tests and maintains customer electric meters in accordance with regulatory standards and organization's guidelines",
        "May interact with customers by answering questions (e.g., about billing/energy usage, smart meters) or directing them to customer service or collections representatives",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [4, 5],
          levelText: "P4 - P5",
        },
        {
          bandShortForm: "T",
          levels: [1, 2, 3, 4],
          levelText: "T1 - T4",
        },
      ],
      topSkills: [
        "Business Process Improvements",
        "Distribution Meter Installation",
        "Distribution Meter Maintenance & Repair",
        "Distribution Meter Tuning",
        "Inventory Management",
        "Meter Testing",
        "Occupational Safety & Health",
        "Records Management",
        "Smart Meters",
        "Standards Compliance",
        "Troubleshooting",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Mgr Elec Metering Services"] },
          destinations: [
            {
              jobFamilySlug:
                "electric-gas-distribution-construction-operations-maintenance",
              jobNames: ["Mgr Cust Ops Construction"],
            },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Electric Distribution/Substation Equipment Testing/Repair",
      slug: "electric-distribution-substation-equipment-testing-repair",
      descriptions: [
        "Ensures that equipment systems meet organization and industry standards",
        "Maintains knowledge of current test equipment and procedures",
        "Operational duties to construct, maintain and operate substation equipment",
        "Reviews equipment test results to identify severity/deficiency and contacts internal/external technical support for assistance as necessary",
        "Utilizes computer apps to complete test forms and generate basic reports",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [4, 5],
          levelText: "P4 - P5",
        },
      ],
      topSkills: [
        "Asset Management",
        "Data Management",
        "Electrical Engineering",
        "Equipment Commissioning",
        "Equipment Testing",
        "Maintenance Inspections; Site Inspections; Mechanical Inspections",
        "Predictive Maintenance",
        "Records Management",
        "Standards Compliance",
        "Troubleshooting",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Engineer"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
      ],
    },
    {
      name: "Energy Delivery/Distribution Generalist/Multidiscipline",
      slug: "energy-delivery-distribution-generalist-multidiscipline",
      descriptions: [
        "Directs and coordinates distribution activities that provide electric and/or gas service to customers",
        "Has responsibilities that typically include operations, line construction and/or maintenance and field engineering",
        "Plans, directs and coordinates activities to prevent electric/gas service interruptions and minimize the impact and restoration time of service interruptions",
        "Schedules maintenance, trouble operations/service restoration, metering operations and operations planning work",
        "Provides adequate, accurate information to the customer service centers to keep affected customers properly informed",
        "Ensures compliance with all safety, training and auditable guidelines, policies, practices, organization standards and government regulations",
        "Interfaces with customers both before and after providing services in order to ensure they have a positive experience",
        "Oversees and tracks budget utilization to ensure efficient and effective use of budgets",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
        {
          bandShortForm: "T",
          levels: [1, 2, 3, 4],
          levelText: "T1 - T4",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Business Process Improvements",
        "Customer Experience Management",
        "Energy Management; Electricity Delivery; Power Delivery",
        "Energy Management System; Power Distribution System; Distribution Management Systems",
        "Financial Management",
        "Health, Safety and Environmental (HSE) Management",
        "Operations Management",
        "Outage Management",
        "Project and Program Management",
        "Regulatory Compliance",
        "Resource Optimization",
        "Stakeholder Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Mgr Customer Operations"] },
          destinations: [
            {
              jobFamilySlug:
                "electric-gas-distribution-construction-operations-maintenance",
              jobNames: ["Sr Mgr Cust Ops Construction"],
            },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 3,
          },
        },
        {
          source: { jobNames: ["TL Customer Operations"] },
          destinations: [
            {
              jobFamilySlug: "electric-gas-distribution-systems-operations",
              jobNames: ["TL Distribution Systems Ops"],
            },
            {
              jobFamilySlug: "vegetation-management-generalist-multidiscipline",
              jobNames: ["TL Line Clearance & Inspection"],
            },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Gas Transmission & Metering",
      slug: "gas-transmission-metering",
      descriptions: [
        "Reads gas meters and records usage data (manually or electronically)",
        "Tests, repairs and maintains stocks of metering equipment and meter testing equipment, including smart meters",
        "Installs, removes, field tests and maintains customer gas meters in accordance with regulatory standards and organization's guidelines",
        "Operates gas transmission system to ensure adequate distribution of gas to meet power demands",
        "Responsible for gas gate station and regulator station installation, operations and maintenance, including gas odorization",
        "Operates and maintains transmission pipelines typically within a geographic area",
        "Coordinates inspection of pipelines, corrosion control, coordination of construction and/or maintenance, and project documentation",
        "Ensures that all policies and procedures for safe operations are maintained and trains personnel on all procedures",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [3],
          levelText: "P3",
        },
      ],
      topSkills: [
        "Data Analysis",
        "Emergency Response",
        "Gas Distribution; Gas Systems",
        "Gas Transmission; Gas Distribution",
        "Maintenance Inspections; Site Inspections; Mechanical Inspections",
        "Inventory Management",
        "Technical Installations",
        "Meter Testing",
        "Occupational Safety & Health",
        "Pressure Monitoring",
        "Records Management",
        "Smart Meters",
        "Standards Compliance",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Engineer Senior"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
      ],
    },
    {
      name: "Vegetation Management Generalist/Multidiscipline",
      slug: "vegetation-management-generalist-multidiscipline",
      descriptions: [
        "Manage the process to clear vegetation around electric power lines in conformance with specifications to prevent power outages as a generalist or in a combination of Disciplines in the Vegetation Management and Forestry Function",
        "Manages the line clearance and inspection crews in service areas",
        "Develops and evaluates new methods for line clearance, vegetation management, and line inspection in compliance with regulations and standards",
        "Evaluates and resolves customer-related problems, questions and complaints involving vegetation management practices",
        "Acts as the organization's spokesperson on line inspection and vegetation management",
        "May prepare and administer bids and contracts",
        "Manages contractors who perform line clearance, line inspection and vegetation management",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2],
          levelText: "P1 - P2",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Contract Management",
        "Customer Experience Management",
        "Environmental Awareness",
        "Line Clearances",
        "Project and Program Management",
        "Regulatory Compliance",
        "Standards Compliance",
        "Vegetation Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["TL Line Clearance & Inspection"] },
          destinations: [
            {
              jobFamilySlug: "electric-gas-distribution-systems-operations",
              jobNames: ["TL Distribution Systems Ops"],
            },
            {
              jobFamilySlug:
                "energy-delivery-distribution-generalist-multidiscipline",
              jobNames: ["TL Customer Operations"],
            },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 1,
          },
        },
        {
          source: { jobNames: ["Line Clearance Coordinator II"] },
          destinations: [
            {
              jobFamilySlug:
                "electric-gas-distribution-construction-operations-maintenance",
              jobNames: ["Work Order Support Coordinator II"],
            },
          ],
          jobLevel: {
            bandShortForm: "B",
            level: 2,
          },
        },
      ],
    },
  ],
}
