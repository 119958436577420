import React from "react"
import { Link } from "gatsby"

const linkStyle = {
  textDecoration: "none",
  color: "inherit",
}

const UnstyledLink = props => {
  return <Link {...props} style={{ ...linkStyle, ...props.style }}></Link>
}

export default UnstyledLink
