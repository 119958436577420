import React from 'react';
import styled from 'styled-components';

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #d8d8d8;
  margin: ${({ margin }) => margin || '0px'};
`;

export default ({ margin }) => <Divider margin={margin} />;
