export default {
  name: "Customer Support/Operations",
  slug: "customer-support-operations",
  jobFamilies: [
    {
      name: "Billing",
      slug: "billing",
      descriptions: [
        "Prepares, generates, prints, records and mails customer bills for monthly services, such as energy and telecommunications, using an automated high volume billing system",
        "Ensures that the automated billing system is operated and maintained in a manner that efficiently produces accurate bills",
        "Prepares daily, monthly and other periodic billing summary reports and financial statements",
        "Maintains, monitors and analyzes account records",
        "Investigates, analyzes, negotiates, resolves, documents and reports on consumer and commercial billing issues and complaints against the organization",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2],
          levelText: "P1 - P2",
        },
        {
          bandShortForm: "T",
          levels: [1, 2, 3, 4],
          levelText: "T1 - T4",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Business Process Improvements",
        "Issue Management",
        "Billing",
        "Continual Improvement Process",
        "Customer Relationship Management",
        "Process Automations",
        "Regulatory Compliance",
        "Root Cause Analyses",
        "Training People",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Billing Integrity Specialist II"] },
          destinations: [
            { jobFamilySlug: "customer-support-center", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Non-Energy Billing Specialist I"] },
          destinations: [
            { jobFamilySlug: "customer-support-center", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Customer Support Center",
      slug: "customer-support-center",
      descriptions: [
        "Provides timely responses to customer inquiries by telephone, email or website chatbot in an in- or outbound service center, consistent with service and quality standards",
        "Processes customer orders and applications for service, maintenance and termination",
        "Troubleshoots and resolves customer complaints",
        "Plans and monitors customer contact center schedules and operations",
        "Analyzes and measures the effectiveness of existing contact center processes and develops sustainable, repeatable and quantifiable process improvements",
        "Provides training and development for contact center staff through continual review, feedback and on-the-job development",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2],
          levelText: "P1 - P2",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Active Listening",
        "Business Process Improvements",
        "Complaints Management",
        "Data Analysis",
        "Issue Management",
        "Issue Resolution",
        "Quality Management",
        "Continual Improvement Process",
        "Customer Relationship Management",
        "Customer Service",
        "Records Management",
        "Training People",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Customer Support Coordinator"] },
          destinations: [
            { jobFamilySlug: "billing", jobNames: [] },
            { jobFamilySlug: "customer-support-operations", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "B",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Customer Support Coordinator"] },
          destinations: [
            { jobFamilySlug: "billing", jobNames: [] },
            { jobFamilySlug: "customer-support-operations", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "B",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Customer Support Operations",
      slug: "customer-support-operations",
      descriptions: [
        "Responsibilities are within the Customer Support/Operations Function as a generalist or in a combination of Disciplines",
        "Provides new and existing customers with the best possible service in relation to billing inquiries, service requests, suggestions and complaints",
        "Resolves customer inquiries and complaints fairly and effectively",
        "Provides product and service information to customers and identifies upselling opportunities to maintain and increase income streams from customer relationships",
        "Recommends and implements programs to support customer needs",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2],
          levelText: "P1 - P2",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Active Listening",
        "Business Process Improvements",
        "Business Productivity",
        "Complaints Management",
        "Customer Needs",
        "Data Analysis",
        "Data Management",
        "Issue Management",
        "Issue Resolution",
        "Operational Excellence",
        "Project and Program Management",
        "Quality Management",
      ],
      potentialMovements: [
        {
          source: {
            jobNames: [
              "Sr Cust Owned Gen Coordinator",
              "Sr Customer Quality Coordinator",
              "Sr Customer Relations Coordinator",
            ],
          },
          destinations: [
            { jobFamilySlug: "customer-support-center", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "B",
            level: 3,
          },
        },
      ],
    },
  ],
}
