export default {
  name: "Legal",
  slug: "legal",
  jobFamilies: [
    {
      name: "Claims Resolution",
      slug: "claims-resolution",
      descriptions: [
        "Oversees claims operations investigation and settlement of property damage, business interruption, and personal injury claims against the organization",
        "Oversees claims operations for damage to organization equipment and/or property against insurance carriers",
        "Provides approval within their authority for claim settlements against the organization",
        "Works with insurance companies, contractors, repair facilities, internal and external legal staff and law enforcement agencies in the settlement of claims",
        "Coordinates and conducts site investigations to determine the extent of liability attributed to the company and the extent of damages claimed by a third party",
        "Submits reports to State Utility Regulatory bodies, and Federal Medicare agencies",
      ],
      jobLevels: [
        {
          bandShortForm: "T",
          levels: [1, 2, 3, 4],
          levelText: "T1 - T4",
        },
      ],
      topSkills: [
        "Conflict Management",
        "Regulatory Compliance",
        "Business Process Improvements",
        "Business Process Standardization",
        "Claims Evaluation",
        "Claims Management",
        "Customer Experience Management",
        "Data Analysis",
        "Insurance Claims Investigation",
        "Insurance Management",
        "Legal Liability",
        "Records Management",
        "Risk Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Claims Representative"] },
          destinations: [
            {
              jobFamilySlug: "contract-management",
              jobNames: ["Sr Contracts Coordinator"],
            },
            { jobFamilySlug: "paralegal", jobNames: ["Sr Paralegal"] },
          ],
          jobLevel: {
            bandShortForm: "T",
            level: 3,
          },
        },
      ],
    },
    {
      name: "Contract Management",
      slug: "contract-management",
      descriptions: [
        "Participates in the development and fulfillment of contract requirements in the purchase or sale/delivery of equipment, materials, products or services",
        "Evaluates contract compliance and advises others on contractual rights and obligations",
        "Requests or approves amendments to contract terms or contract extensions",
        "Negotiates contract terms and participates in the determination of acceptable bids",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [3],
          levelText: "P3",
        },
        {
          bandShortForm: "T",
          levels: [1, 2, 3, 4],
          levelText: "T1 - T4",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Business Acumen",
        "Business Negotiations",
        "Regulatory Compliance",
        "Business Process Improvements",
        "Business Process Standardization",
        "Contract Lifecycle Management",
        "Contract Management",
        "Contracts Formation",
        "Drafting Documents",
        "Policy Analysis",
        "Pricing Analysis",
        "Quality Management",
        "Records Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Contracts Coordinator"] },
          destinations: [
            {
              jobFamilySlug: "contract-management",
              jobNames: ["Sr Claims Representative"],
            },
            { jobFamilySlug: "paralegal", jobNames: ["Sr Paralegal"] },
          ],
          jobLevel: {
            bandShortForm: "T",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Sr Contracts Coordinator"] },
          destinations: [
            {
              jobFamilySlug: "government-relations-public-affairs",
              jobNames: ["Sr Legal & Public Affairs Coordinator"],
            },
          ],
          jobLevel: {
            bandShortForm: "B",
            level: 3,
          },
        },
      ],
    },
    {
      name: "Government Relations & Public Affairs",
      slug: "government-relations-public-affairs",
      descriptions: [
        "Develops and maintains external relationships to advocate for policies and programs that affect the organization",
        "Ensures that the organization's interests are represented and protected in Federal and State legislative proceedings",
        "Prepares and organizes forums and gatherings with government officials to advocate on public policy priorities that may affect the organization",
        "Partners with regulatory on legislative enablers in support of the regulatory priorities of the organization",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [3, 4, 5],
          levelText: "P3 - P5",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Business Acumen",
        "Professional Advocacy",
        "Regulatory Compliance",
        "Competitive Intelligence",
        "Data Interpretations",
        "Government Relations",
        "Lobbying",
        "Market Analysis",
        "Policy Analysis",
        "Policy Guidance",
        "Political Strategies",
        "Project and Program Management",
        "Storytelling",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Reg Relat & Policy Consultant"] },
          destinations: [
            {
              jobFamilySlug: "legal-generalist-multidiscipline",
              jobNames: ["Lead Corporate Counsel"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Sr Legal & Public Affairs Coordinator"] },
          destinations: [
            {
              jobFamilySlug: "contract-management",
              jobNames: ["Sr Contracts Coordinator"],
            },
          ],
          jobLevel: {
            bandShortForm: "B",
            level: 3,
          },
        },
      ],
    },
    {
      name: "Legal Generalist/Multidiscipline",
      slug: "legal-generalist-multidiscipline",
      descriptions: [
        "Responsibilities are within the Legal Function as a generalist or in a combination of Disciplines",
        "Provides a full range of legal advice and services",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [4, 5],
          levelText: "P4 - P5",
        },
      ],
      topSkills: [
        "Business Acumen",
        "Business Negotiations",
        "Conflict Management",
        "Contractor Management",
        "Corporate Governance",
        "Legal Consulting",
        "Legal Writing",
        "Litigation",
        "Professional Advocacy",
        "Regulatory Compliance",
      ],
      potentialMovements: [],
    },
    {
      name: "Paralegal",
      slug: "paralegal",
      descriptions: [
        "Performs a variety of law-related tasks under the direction of the organization's attorneys",
        "Conducts legal research, drafts legal documents and performs other related duties",
        "Has or is working towards a paralegal designation",
      ],
      jobLevels: [
        {
          bandShortForm: "T",
          levels: [1, 2, 3, 4],
          levelText: "T1 - T4",
        },
      ],
      topSkills: [
        "Corporate Governance",
        "Legal Consulting",
        "Legal Writing",
        "Regulatory Compliance",
        "Contract Administration",
        "Contract Lifecycle Management",
        "Contracts Formation",
        "Drafting Documents",
        "Legal Research",
        "Project and Program Management",
        "Records Management",
        "Technical Reports",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Paralegal"] },
          destinations: [
            {
              jobFamilySlug: "contract-management",
              jobNames: ["Sr Contract Specialist"],
            },
          ],
          jobLevel: {
            bandShortForm: "T",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Sr Paralegal"] },
          destinations: [
            {
              jobFamilySlug: "claims-resolution",
              jobNames: ["Sr Claims Representative"],
            },
            {
              jobFamilySlug: "contract-management",
              jobNames: ["Sr Contract Specialist"],
            },
          ],
          jobLevel: {
            bandShortForm: "T",
            level: 3,
          },
        },
      ],
    },
  ],
}
