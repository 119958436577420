export default {
  name: "Generation",
  slug: "generation",
  jobFamilies: [
    {
      name: "Generation Generalist/Multidiscipline",
      slug: "generation-generalist-multidiscipline",
      descriptions: [
        "Maintains power generation facilities and mechanical equipment in safe, efficient and economical working condition, including the direction of maintenance engineering",
        "Maximizes the power generated by the renewable/alternative generation plant/facility to meet system load requirements",
        "Takes responsibility for the operation of generator units and associated equipment, operation of fossil fuel handling and waste disposal equipment, and maintenance activities, including maintenance engineering",
        "Oversees the replacement, installation and removal of mechanical and/or electrical equipment and associated structures",
        "Maintains generation facility operating records and performance reports",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
      ],
      topSkills: [
        "Asset Life Cycle Management",
        "Budget Management",
        "Continual Improvement Process",
        "Corrective Maintenance",
        "Preventative Maintenance",
        "Records Management",
        "Operations Management",
        "Environmental Awareness",
        "Equipment Repair Maintenance",
        "Power Generation Optimization",
        "Stakeholder Management",
        "Vegetation Management",
      ],
      potentialMovements: [
        {
          source: {
            jobNames: ["Mgr Customer Operations", "Mgr Renewable Assets"],
          },
          destinations: [],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Power Generation Maintenance",
      slug: "power-generation-maintenance",
      descriptions: [
        "Takes responsibility for short- and long-range maintenance planning",
        "Directs outage-related activities including planning and scheduling support for routine and emergency outages, consistent with the organization’s policy and regulatory requirements",
        "Coordinates cost estimates and procurement, and designs support plans prior to and during an outage",
        "Monitors progress of the outage, identifies problems, revises schedules and budgets, and prepares reports and other documentation on the status of the outage to management",
        "Coordinates contracted and organization maintenance staff resources; monitors spare parts inventory and system load requirements to minimize overall time and cost of maintenance activities",
        "Creates, reviews and evaluates overhaul and outage reports and incorporates improvement techniques in future procedures and plans",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
      ],
      topSkills: [
        "Asset Life Cycle Management",
        "Condition Monitoring",
        "Continual Improvement Process",
        "Corrective Maintenance",
        "Preventative Maintenance",
        "Records Management",
        "Energy System Performance Monitoring",
        "Equipment Installations",
        "Inventory Management",
        "Maintenance Management",
        "Occupational Safety & Health",
        "Outage Management",
        "Predictive Maintenance",
        "Resource Optimization",
        "Root Cause Analysis",
        "Systems Performance Monitoring",
        "Vegetation Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Mgr Maintenance"] },
          destinations: [
            { jobFamilySlug: "power-generation-operations", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Power Generation Operations",
      slug: "power-generation-operations",
      descriptions: [
        "Meets system load requirements of the plant engaged in operation of boiler-turbine generator units and associated equipment, operation of fuel handling and waste disposal equipment",
        "Maximizes the power generated by the renewable/alternative generation facility and maintains electrical and mechanical equipment",
        "Maintains plant or renewable/alternative facility operating records and performance reports",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
      ],
      topSkills: [
        "Asset Life Cycle Management",
        "Condition Monitoring",
        "Continual Improvement Process",
        "Records Management",
        "Operations Management",
        "Energy System Performance Monitoring",
        "Generation Dispatch Estimation",
        "Incident Management",
        "Occupational Safety & Health",
        "Resource Optimization",
        "Root Cause Analysis",
        "Electrical Load Analysis; Systems Performance Analysis",
        "Systems Performance Monitoring",
        "Troubleshooting",
        "Vegetation Management",
        "Workflow Optimization",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Mgr GENCO Operations"] },
          destinations: [
            { jobFamilySlug: "power-generation-maintenance", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
        {
          source: { jobNames: ["TL Shift Operations"] },
          destinations: [
            { jobFamilySlug: "power-generation-maintenance", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Fuel Yard",
      slug: "fuel-yard",
      descriptions: [
        "Takes responsibility for fossil fuel yard activities, including fueling operations, fuel-handling equipment maintenance and management, and ash pond and coal pile",
        "Coordinates with fossil fuel suppliers, railroad dispatchers, shippers and purchasing regarding fossil fuel deliveries",
      ],
      jobLevels: [
        {
          bandShortForm: "",
          levels: [],
          levelText: "",
        },
      ],
      topSkills: [
        "Condition Monitoring",
        "Corrective Maintenance",
        "Preventative Maintenance",
        "Environmental Awareness",
        "Equipment Repair Maintenance",
        "Fueling Management Systems; Fuel Handling",
        "Occupational Safety & Health",
        "Regulatory Compliance",
      ],
      potentialMovements: [
        {
          source: { jobNames: [] },
          destinations: [
            {
              jobFamilySlug: "generation-generalist-multidiscipline",
              jobNames: [],
            },
            {
              jobFamilySlug: "power-generation-maintenance",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: [] },
          destinations: [
            {
              jobFamilySlug: "generation-generalist-multidiscipline",
              jobNames: [],
            },
            {
              jobFamilySlug: "power-generation-maintenance",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: [] },
          destinations: [
            {
              jobFamilySlug: "generation-generalist-multidiscipline",
              jobNames: [],
            },
            {
              jobFamilySlug: "power-generation-maintenance",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
  ],
}
