export default {
  name: "Engineering and Technical Specialty",
  slug: "engineering-and-technical-specialty",
  jobFamilies: [
    {
      name: "Electric System/ Grid Operations Engineering",
      slug: "electric-system-grid-operations-engineering",
      descriptions: [
        "Develops, implements and monitors engineering applications, processes and services associated with the performance of all coordinated operations responsibilities assigned to the Independent Coordinator of Transmission (ICT) to prevent power outages and maintain reliable electric service",
        "Requests and collects information, prepares and validates data and executes computer runs for current, next-day and other operational assessments of the transmission network to support coordination and approval of generation and transmission maintenance outage requests and to comply with external standards and requirements, including reporting for market participants, government entities and legal",
        "Participates in identification of issues and researches, analyzes and resolves disputes and inquires relating to market and grid operations data",
        "Develops and periodically updates network model data as necessary for the Interchange Distribution Calculator (IDC) load flow model",
        "Develops, maintains and supports coordinated operations applications, processes and documentation",
        "Identifies opportunities for improved analyses of electric system operations data and prioritizes, develops and implements improvements, including automated tools for monitoring and reporting operations data",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Continual Improvement Process",
        "Cost Optimization",
        "Power Engineering",
        "Root Cause Analysis",
        "Asset Life Cycle Management",
        "Data Analysis",
        "Data Quality Management",
        "Electrical Engineering",
        "Grid Operations Management",
        "Outage Management",
        "Process Controls Engineering",
        "Standards Compliance",
        "Systems Performance Monitoring",
      ],
      potentialMovements: [
        {
          source: {
            jobNames: ["Mgr System Protection", "Manager Engineering"],
          },
          // L2 managers can move across all Job Families within this Job Family Group
          destinations: [
            {
              jobFamilySlug: "electric-system-grid-planning-engineering",
              jobNames: [],
            },
            { jobFamilySlug: "design-standards-engineering", jobNames: [] },
            { jobFamilySlug: "power-generation-engineering", jobNames: [] },
            {
              jobFamilySlug: "cad-cae-drafting-technical-specialty",
              jobNames: [],
            },
            { jobFamilySlug: "gas-engineering", jobNames: [] },
            { jobFamilySlug: "substation-engineering", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Electric System/Grid Planning Engineering",
      slug: "electric-system-grid-planning-engineering",
      descriptions: [
        "Takes responsibility for regional distribution system planning, including load/zone planning and baseline reliability planning, to ensure reliable operation under various operating scenarios in compliance with regulatory planning standards and guidelines",
        "Interfaces with load-serving entities, generation entities, regulatory agencies, regional reliability councils, and other providers to obtain necessary inputs into the planning process",
        "Identifies and documents recurring system operation conditions and trends (e.g., low voltage, unusual flows) requiring planning attention",
        "Assesses the capabilities of the regional distribution system and its compliance with the planning criteria",
        "Conducts load saturation studies to determine the long-range substation needs in the service territory",
        "Conducts power flow studies, builds power flow models, and prepares economic analyses to develop short- and long-term distribution system plans",
        "Develops expansion plans for the regional distribution system and substations",
        "Provides technical support to licensing and certification proceedings related to new distribution lines and substation sites",
        "May represent the organization at Regional Transmission Councils and/or Regional Transmission Organizations",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Cost Optimization",
        "Power Engineering",
        "Data Analysis",
        "Feasibility Studies",
        "Grid Interconnection Studies",
        "Power Flow Analysis; Flow Modeling",
        "Systems Performance Monitoring",
        "Power Systems Planning",
        "Regulatory Compliance",
        "Short Circuit Analysis; Short Circuit Calculations",
        "Stakeholder Management",
        "Standards Compliance",
        "Transient Analysis",
      ],
      potentialMovements: [
        {
          source: {
            jobNames: ["Sr Mgr Power Delivery"],
          },
          // L3 managers can move across all Job Families within this Job Family Group (as long as there is enough depth at P4/P5 level)
          destinations: [
            {
              jobFamilySlug: "electric-system-grid-operations-engineering",
              jobNames: [],
            },
            { jobFamilySlug: "power-generation-engineering", jobNames: [] },
            { jobFamilySlug: "gas-engineering", jobNames: [] },
            { jobFamilySlug: "substation-engineering", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 3,
          },
        },
        {
          source: {
            jobNames: [
              "Mgr Distribution Engineering",
              "Mgr Pwr Qulty & Dstrib Gen Eng",
            ],
          },
          // L2 managers can move across all Job Families within this Job Family Group
          destinations: [
            {
              jobFamilySlug: "electric-system-grid-operations-engineering",
              jobNames: [],
            },
            { jobFamilySlug: "design-standards-engineering", jobNames: [] },
            { jobFamilySlug: "power-generation-engineering", jobNames: [] },
            {
              jobFamilySlug: "cad-cae-drafting-technical-specialty",
              jobNames: [],
            },
            { jobFamilySlug: "gas-engineering", jobNames: [] },
            { jobFamilySlug: "substation-engineering", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Design Standards Engineering",
      slug: "design-standards-engineering",
      descriptions: [
        "Develops construction working drawings and instructional standards, material and tool specifications, and acceptable suppliers’ lists",
        "Directs or performs the investigative, testing and technical work necessary for developing design specifications and material lists",
        "Develops policies, procedures and methods to evaluate and improve the quality of products, materials, components and/or operations",
        "Develops, assures and maintains the quality of products and processes, including standard procedures (e.g., ISO 9001:2000, ISO 14000 family of standards), quality audits/review, Taguchi methods, process reengineering, etc.",
        "Collaborates with suppliers to assure the quality of their products, materials, components and/or operations",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [3],
          levelText: "P3",
        },
      ],
      topSkills: [
        "Root Cause Analysis",
        "Design Specifications",
        "Engineering Design",
        "Engineering Drawings",
        "Health, Safety and Environmental (HSE) Management",
        "Quality Auditing",
        "Regulatory Compliance",
        "Solutions Design",
        "Specification Development",
        "Standards Compliance",
        "Writing Technical Documents",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Standards Specialist", "Sr Engineer"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
      ],
    },
    {
      name: "Power Generation Engineering",
      slug: "power-generation-engineering",
      descriptions: [
        "Designs, develops, and improves fossil power plant operations and/or maintains plant facilities and mechanical equipment in safe, efficient and economical working conditions through the direction or performance of maintenance activities",
        "Supports Power Generation facilities by leading projects to replace aging equipment.",
        "May also be involved in engineering effectiveness studies relating to installation, calibration, maintenance and repair of instrumentation",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
        {
          bandShortForm: "T",
          levels: [1, 2, 3, 4],
          levelText: "T1 - T4",
        },
      ],
      topSkills: [
        "Condition Monitoring",
        "Continual Improvement Process",
        "Cost Optimization",
        "Maintenance Inspections; Site Inspections; Mechanical Inspections",
        "Power Engineering",
        "Predictive Maintenance",
        "Project and Program Management",
        "Records Management",
        "Root Cause Analysis",
        "Design Specifications",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Mgr Generation"] },
          // L3 managers can move across all Job Families within this Job Family Group (as long as there is enough depth at P4/P5 level)
          destinations: [
            {
              jobFamilySlug: "electric-system-grid-operations-engineering",
              jobNames: [],
            },
            {
              jobFamilySlug: "electric-system-grid-planning-engineering",
              jobNames: [],
            },
            { jobFamilySlug: "gas-engineering", jobNames: [] },
            { jobFamilySlug: "substation-engineering", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Mgr Generation Engineering"] },
          // L2 managers can move across all Job Families within this Job Family Group
          destinations: [
            {
              jobFamilySlug: "electric-system-grid-operations-engineering",
              jobNames: [],
            },
            {
              jobFamilySlug: "electric-system-grid-planning-engineering",
              jobNames: [],
            },
            { jobFamilySlug: "design-standards-engineering", jobNames: [] },
            {
              jobFamilySlug: "cad-cae-drafting-technical-specialty",
              jobNames: [],
            },
            { jobFamilySlug: "gas-engineering", jobNames: [] },
            { jobFamilySlug: "substation-engineering", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
      ],
    },
    {
      name: "CAD/ CAE Drafting Technical Specialty",
      slug: "cad-cae-drafting-technical-specialty",
      descriptions: [
        "Prepares and maintains detailed design drawings, schematics or specifications typically using computer-aided design software",
        "Works closely with design originators (e.g., engineers, designers) to resolve design details or discrepancies or to prepare drawings of unusual, complex or original designs which require a high degree of precision using computer-aided drafting (CAD) or computer-aided engineering (CAE) equipment",
        "Creates, modifies and controls detailed two- and three-dimensional parts and assembly drawings from engineers' and technicians' sketches, plans and written and verbal instructions",
        "Develops and maintains drafting standards and procedures",
        "Evaluates and recommends purchase of CAD/CAE-related computer hardware and software",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2],
          levelText: "P1 - P2",
        },
        {
          bandShortForm: "T",
          levels: [1, 2, 3, 4],
          levelText: "T1 - T4",
        },
      ],
      topSkills: [
        "Records Management",
        "3D Modeling",
        "Computer-Aided Design (CAD)",
        "Design Engineering",
        "Engineering Drawings",
        "Layout Designs",
        "Power Plant Design; Substation Design",
        "Quality Management Standards",
        "Technical Documents",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Engineering Content Mgmt Analyst"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Gas Engineering",
      slug: "gas-engineering",
      descriptions: [
        "Develops and periodically updates network gas model data as necessary for load flow model",
        "Develops, maintains and supports coordinated gas operations applications, processes and documentation",
        "Identifies opportunities for improved analyses of gas system operations data and prioritizes, develops and implements improvements, including automated tools for monitoring and reporting operations data.",
        "Interfaces with load-serving entities, pipeline entities, regulatory agencies, and other providers to obtain necessary inputs into the planning process",
        "Identifies and documents recurring system operation conditions and trends (e.g., low pressure, unusual flows) requiring planning attention",
        "Provides technical guidance and support to gas field employees and field designers",
        "Monitors local system demand trends to determine the long-range gate station needs in the service territory",
        "Conducts gas flow studies, builds gas flow models, and prepares economic analyses to develop short- and long-term gas distribution system plans",
        "Develops expansion plans for the regional distribution system and gate stations",
        "Leads or supports company’s Integrity Management Programs",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Continual Improvement Process",
        "Cost Optimization",
        "Root Cause Analysis",
        "Asset Life Cycle Management",
        "Data Analysis",
        "Data Quality Management",
        "Gas Operations",
        "Mechanical Engineering",
        "Standards Compliance",
        "Systems Performance Monitoring",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Mgr Gas Engineering"] },
          // L2 managers can move across all Job Families within this Job Family Group
          destinations: [
            {
              jobFamilySlug: "electric-system-grid-operations-engineering",
              jobNames: [],
            },
            {
              jobFamilySlug: "electric-system-grid-planning-engineering",
              jobNames: [],
            },
            { jobFamilySlug: "design-standards-engineering", jobNames: [] },
            { jobFamilySlug: "power-generation-engineering", jobNames: [] },
            {
              jobFamilySlug: "cad-cae-drafting-technical-specialty",
              jobNames: [],
            },
            { jobFamilySlug: "substation-engineering", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
      ],
    },
    {
      name: "Substation Engineering",
      slug: "substation-engineering",
      descriptions: [
        "Designs, develops, and improves substation operations in safe, efficient and economical working conditions through the direction or performance of maintenance activities.",
        "Coordinates with engineering firms that provides design support, providing direction based on substation design templates",
        "Provides technical assistance when trouble shooting a failed transformer.",
        "Provides technical assistance to monitor transformer health and provides guidance to manage the life-cycle of the assets.",
        "Helps maintain company's substation templates and standards, including substation size, transformers, breakers, reclosures, control houses, etc.",
        "Provides on-site technical support during construction phase of new substations.",
        "Helps evaluate new components for potential implementation in company substation standards",
        "Coordinates with System Protection selecting protective relay and associated equipment.",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Condition Monitoring",
        "Continual Improvement Process",
        "Cost Optimization",
        "Maintenance Inspections; Site Inspections; Mechanical Inspections",
        "Power Engineering",
        "Predictive Maintenance",
        "Project and Program Management",
        "Records Management",
        "Root Cause Analysis",
        "Design Specifications",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Mgr Electrical Engineering"] },
          // L3 managers can move across all Job Families within this Job Family Group (as long as there is enough depth at P4/P5 level)
          destinations: [
            {
              jobFamilySlug: "electric-system-grid-operations-engineering",
              jobNames: [],
            },
            {
              jobFamilySlug: "electric-system-grid-planning-engineering",
              jobNames: [],
            },
            { jobFamilySlug: "power-generation-engineering", jobNames: [] },
            { jobFamilySlug: "gas-engineering", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Mgr Substation Engineering"] },
          // L2 managers can move across all Job Families within this Job Family Group
          destinations: [
            {
              jobFamilySlug: "electric-system-grid-operations-engineering",
              jobNames: [],
            },
            {
              jobFamilySlug: "electric-system-grid-planning-engineering",
              jobNames: [],
            },
            { jobFamilySlug: "design-standards-engineering", jobNames: [] },
            { jobFamilySlug: "power-generation-engineering", jobNames: [] },
            {
              jobFamilySlug: "cad-cae-drafting-technical-specialty",
              jobNames: [],
            },
            { jobFamilySlug: "gas-engineering", jobNames: [] },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
      ],
    },
  ],
}
