export default {
  name: "Environmental and Sustainability",
  slug: "environmental-and-sustainability",
  jobFamilies: [
    {
      name: "Environmental Compliance and Science",
      slug: "environmental-compliance-and-science",
      descriptions: [
        "Provides advanced technical knowledge and expertise in interpreting and monitoring compliance with regulatory requirements and industry guidelines",
        "Assists with the development of strategies on various environmental policy and compliance issues",
        "Interfaces with industry, government agencies, environmental groups, and regulatory agencies as an environmental expert",
        "Brings advanced environmental engineering or scientific knowledge to critical business matters and operations",
        "Interprets environmental conditions and works closely with external relations representatives on business solutions",
        "Develops and implements programs to ensure that environment regulatory obligations are fulfilled in a cost-effective manner and that environmental risks are effectively managed",
        "Analyzes and maintains detailed records of pollutant concentrations in air, water, plant and soil samples",
        "Assists field locations in pollution prevention and waste minimization programs through periodic monitoring and technical guidance",
        "Conducts research on the degradation of pollutants in the environment, including streams, sediments and groundwater",
        "Maintains detailed records and prepares related reports and studies for submission to regulatory agencies",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Data Analysis",
        "Environmental Awareness",
        "Environmental Impact Assessment",
        "Environmental Management Systems",
        "Environmental Monitoring Techniques",
        "Environmental Permitting",
        "Environmental Reports",
        "Policy Analysis",
        "Process Automations",
        "Project and Program Management",
        "Regulatory Compliance",
        "Risk Management",
        "Stakeholder Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Environmental Specialist"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Environmental Specialist II"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Environmental Specialist I"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Sustainability",
      slug: "sustainability",
      descriptions: [
        "Plans, implements and maintains the organization's sustainability programs to reduce the use of nonrenewable resources and minimize the environmental impact of operations",
        "Leads company response to Environmental, Social and Governance (ESG) related matters and advises leadership on appropriate disclosures",
        "Provides specialized technical guidance and expertise in interpreting and monitoring compliance with regulatory requirements, industry guidelines and ESG alignment",
        "Develops and implements strategies on various ESG policy and compliance issues and interfaces with industry, government agencies, environmental groups, investment stakeholders and regulatory agencies as an ESG expert and company representative",
        "Brings expert ESG and sustainability knowledge to critical business matters and operations.",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [4, 5],
          levelText: "P4 - P5",
        },
      ],
      topSkills: [
        "Environmental Reports",
        "Policy Analysis",
        "Project and Program Management",
        "Regulatory Compliance",
        "Stakeholder Management",
        "ESG Analysis",
        "Professional Advocacy",
        "Strategic Communication",
        "Sustainability Management",
        "Sustainability Risk Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead ESG Consultant"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
      ],
    },
  ],
}
