export default {
  name: "Corporate Affairs",
  slug: "corporate-affairs",
  jobFamilies: [
    {
      name: "Community Affairs / Relations and Foundation",
      slug: "community-affairs-relations-and-foundation",
      descriptions: [
        "Develops and maintains a favorable relationship with the surrounding community",
        "Develops and coordinates programs to promote good will by disseminating information to the community and soliciting feedback from residents",
        "Organizes and supports employee volunteer programs and coordinates with local groups",
        "Steward and participate in the organization's charitable giving programs as they affect community initiatives",
        "Represents the organization at community gatherings or forums",
        "Reviews the impact of the organization's social responsibility programs and activities on key stakeholders (e.g., employees, shareholders, communities)",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [4, 5],
          levelText: "P4 - P5",
        },
      ],
      topSkills: [
        "Audience Segmentation",
        "Budget Management",
        "Campaign Development",
        "Community Outreach",
        "Data Analysis",
        "Project and Program Management",
        "Public Affairs Strategies",
        "Reputation Management",
        "Researching",
        "Strategic Communication",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Communiity Affairs Specialist"] },
          destinations: [
            {
              jobFamilySlug:
                "corporate-affairs-communications-generalist-multidiscipline",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
      ],
    },
    {
      name: "Corporate Affairs/Communications Generalist/Multidiscipline",
      slug: "corporate-affairs-communications-generalist-multidiscipline",
      descriptions: [
        "Responsibilities are within the Corporate Affairs/Communications Function as a generalist or in a combination of Disciplines",
        "Researches, develops, plans, designs, maintains and implements policies and programs that enhance the organization's relations with the community, the public, government and regulatory authorities, shareholders and employees",
        "Coordinates media relations and prepares external/internal communications",
        "Plans, prepares and relays information concerning the organization to all relevant stakeholders to gain understanding and acceptance for the organization",
        "Develops and maintains lines of communication with internal and external audience groups",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3],
          levelText: "P1 - P3",
        },
      ],
      topSkills: [
        "Budget Management",
        "Campaign Development",
        "Content Development",
        "Crisis Communication Management",
        "Data Analysis",
        "Market Messaging",
        "Project and Program Management",
        "Reputation Management",
        "Researching",
        "Strategic Communication",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Communications Specialist"] },
          destinations: [
            {
              jobFamilySlug: "community-affairs-relations-and-foundation",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Communications Specialist II"] },
          destinations: [
            {
              jobFamilySlug: "community-affairs-relations-and-foundation",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Communications Specialist I"] },
          destinations: [
            {
              jobFamilySlug: "community-affairs-relations-and-foundation",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Investor Relations",
      slug: "investor-relations",
      descriptions: [
        "Maintains and improves relations and communications between the organization and the investing public, shareholders and financial professionals to enhance the standing of the organization's stock and debt",
        "Develops, maintains and enhances working relationships with institutions in the financial community that hold, or may hold, the organization's stock or debt",
        "Monitors changes and trends in investment markets and determines appropriate strategy",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [4, 5],
          levelText: "P4 - P5",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Competitive Market Analysis",
        "Crisis Communication Management",
        "Data Analysis",
        "Data Visualization",
        "Financial Analysis",
        "Investor Relationship Management",
        "Market Messaging",
        "Regulatory Compliance",
        "Researching",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Regltry Solutions Proj Manager"] },
          destinations: [
            {
              jobFamilySlug:
                "corporate-affairs-communications-generalist-multidiscipline",
              jobNames: [],
            },
            {
              jobFamilySlug: "resource-planning",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Sr NERC Stand&Compl Specialist"] },
          destinations: [
            {
              jobFamilySlug:
                "corporate-affairs-communications-generalist-multidiscipline",
              jobNames: [],
            },
            {
              jobFamilySlug: "resource-planning",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["NERC Stand&Cmpl Specialist II"] },
          destinations: [
            {
              jobFamilySlug:
                "corporate-affairs-communications-generalist-multidiscipline",
              jobNames: [],
            },
            {
              jobFamilySlug: "resource-planning",
              jobNames: [],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
  ],
}
