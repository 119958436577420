export default {
  name: "Finance & Accounting",
  slug: "finance-accounting",
  jobFamilies: [
    {
      name: "General Accounting & Reporting",
      slug: "general-accounting-reporting",
      descriptions: [
        "Performs general accounting activities, including the preparation, maintenance and reconciliation of ledger accounts and financial statements such as balance sheets, profit-and-loss statements and capital expenditure schedules",
        "Prepares, records, analyzes and reports accounting transactions and ensures the integrity of accounting records for completeness, accuracy and compliance with accepted accounting policies and principles",
        "Provides financial support, including forecasting, budgeting and analyzing variations from budget",
        "Analyzes and prepares statutory accounts, financial statements and reports",
        "Conducts or assists in the documentation of accounting projects",
        "Conducts extensive research regarding the impact to financial statements of potential changes to accounting rules proposed by accounting standards setters and advises senior management regarding same",
        "Provides accounting guidance related to proposed transactions to ensure conformity with local country Generally Accepted Accounting Principles, International Financial Reporting Standards, and organization policies and procedures",
        "Prepares, records, analyzes and reports on the cost of producing the organization's products and services",
        "Prepares and distributes periodic financial statements for users other than those directly employed by the organization",
        "Ensures all reports and disclosures comply with applicable government regulations, professional standards and organization policies",
        "Prepares consolidation journal entries, eliminates inter-organization transactions and consolidates divisional and subsidiary financial accounts in a timely and accurate basis for inclusion in internal and external financial statements",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Business Process Improvements",
        "Business Process Standardization",
        "Data Analysis",
        "Data Management",
        "Accounting Standards",
        "Corporate Financial Reporting",
        "Data Visualization",
        "Financial Accounting",
        "Financial Analysis",
        "Financial Risk Management",
        "Internal Controls",
        "Records Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Mgr Accounting"] },
          destinations: [
            {
              jobFamilySlug: "treasury-operations",
              jobNames: ["Mgr Treasury"],
            },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Accountant II"] },
          destinations: [
            {
              jobFamilySlug: "tax-planning-reporting",
              jobNames: ["Tax Accountant II"],
            },
            {
              jobFamilySlug: "treasury-operations",
              jobNames: ["Treasury Analyst II"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Accountant I"] },
          destinations: [
            {
              jobFamilySlug: "tax-planning-reporting",
              jobNames: ["Tax Accountant I"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Payroll",
      slug: "payroll",
      descriptions: [
        "Prepares, documents and disburses payroll checks, payroll taxes and employee benefit payments",
        "Evaluates current systems, and recommends and develops operating efficiency improvements",
        "Monitors and ensures proper documentation of employee benefit payments",
        "Prepares reports illustrating payroll expenditures, including such items as tax payments and benefit plan disbursements",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [3, 4, 5],
          levelText: "P3 - P5",
        },
      ],
      topSkills: [
        "Business Process Improvements",
        "Business Process Standardization",
        "Data Analysis",
        "Data Management",
        "Payroll Processing",
        "Payroll Systems",
        "Regulatory Compliance",
      ],
      potentialMovements: [
        {
          source: { jobNames: [] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: [] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
      ],
    },
    {
      name: "Risk Management Generalist/Multidiscipline",
      slug: "risk-management-generalist-multidiscipline",
      descriptions: [
        "Identifies and analyzes potential sources of loss to minimize risk",
        "Estimates the potential financial consequences of an occurring loss",
        "Develops and implements controls and cost-effective approaches to minimize the organization's risks",
        "Assesses and communicates information regarding business risks with functions across the organization",
        "Develops, recommends and administers risk management and loss prevention programs, such as property and casualty insurance and product liability service (excluding insured employee benefit plans), to attain maximum protection at the most economical rates",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [3],
          levelText: "P3",
        },
      ],
      topSkills: [
        "Business Process Improvements",
        "Data Analysis",
        "Regulatory Compliance",
        "Financial Accounting",
        "Financial Risk Management",
        "Insurance Management",
        "Knowledge Management",
        "Loss Prevention Control",
        "Market Analysis",
        "Predictive Analytics",
        "Records Management",
        "Risk Management",
        "Risk Management Framework",
        "Stakeholder Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Sr Insurance & Risk Analyst"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
      ],
    },
    {
      name: "Tax Planning & Reporting",
      slug: "tax-planning-reporting",
      descriptions: [
        "Prepares and maintains tax records, returns, reports and other related materials",
        "Recommends alternative tax treatments to alleviate or reduce tax burden",
        "May be responsible for tax accounting",
        "Advocates for tax positions with governmental agencies",
        "Reviews tax legislation",
        "Assists with external audits",
        "Identifies and analyzes tax risk",
        "Advises Senior Management on tax strategies",
        "Works closely with the business to determine potential tax implications of current projects",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Business Process Improvements",
        "Data Analysis",
        "Data Management",
        "Regulatory Compliance",
        "Corporate Financial Reporting",
        "Financial Accounting",
        "Financial Analysis",
        "Internal Controls",
        "Risk Management",
        "Tax Audits",
        "Tax Law",
        "Tax Planning",
        "Tax Research",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Mgr Tax"] },
          destinations: [
            {
              jobFamilySlug: "treasury-operations",
              jobNames: ["Mgr Treasury"],
            },
          ],
          jobLevel: {
            bandShortForm: "L",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Lead Tax Accountant"] },
          destinations: [
            {
              jobFamilySlug: "general-accounting-reporting",
              jobNames: ["Lead Accountant"],
            },
            {
              jobFamilySlug: "treasury-operations",
              jobNames: ["Lead Treasury Analyst"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Sr Tax Accountant"] },
          destinations: [
            {
              jobFamilySlug: "general-accounting-reporting",
              jobNames: ["Sr Accountant"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Tax Accountant II"] },
          destinations: [
            {
              jobFamilySlug: "treasury-operations",
              jobNames: ["Treasury Analyst II"],
            },
            {
              jobFamilySlug: "general-accounting-reporting",
              jobNames: ["Accountant II"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Tax Accountant I"] },
          destinations: [
            {
              jobFamilySlug: "general-accounting-reporting",
              jobNames: ["Accountant I"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Treasury Operations",
      slug: "treasury-operations",
      descriptions: [
        "Performs treasury operations involving cash funds, foreign exchange, debt and capital management",
        "Provides analytical and technical support to treasury-related activities",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 4, 5],
          levelText: "P1 - P2, P4 - P5",
        },
        {
          bandShortForm: "B",
          levels: [1, 2, 3, 4],
          levelText: "B1 - B4",
        },
      ],
      topSkills: [
        "Business Process Improvements",
        "Data Analysis",
        "Regulatory Compliance",
        "Business Negotiations",
        "Capital Markets",
        "Corporate Financial Reporting",
        "Credit Risk Management",
        "Financial Modeling",
        "Internal Controls",
        "Liquidity Management",
        "Stakeholder Management",
        "Treasury Management",
        "Treasury Management Systems",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Treasury Analyst II"] },
          destinations: [
            {
              jobFamilySlug: "general-accounting-reporting",
              jobNames: ["Accountant II"],
            },
            {
              jobFamilySlug: "tax-planning-reporting",
              jobNames: ["Tax Accountant II"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Sr Treasury Coordinator"] },
          destinations: [
            {
              jobFamilySlug: "general-accounting-reporting",
              jobNames: ["Accountant I"],
            },
          ],
          jobLevel: {
            bandShortForm: "B",
            level: 3,
          },
        },
      ],
    },
    {
      name: "Finance Generalist/Multidiscipline",
      slug: "finance-generalist-multidiscipline",
      descriptions: [
        "Performs a variety of financial activities, including accounting, financial analysis, audit, tax, credit and collections",
        "Develops and evaluates financial plans and policies",
        "Establishes and maintains accounting, financial, and reporting policies and controls",
      ],
      jobLevels: [],
      topSkills: [
        "Business Process Improvements",
        "Data Analysis",
        "Regulatory Compliance",
        "Corporate Financial Reporting",
        "Financial Accounting",
        "Financial Analysis",
        "Financial Planning",
        "Internal Controls",
        "Stakeholder Management",
      ],
      potentialMovements: [
        {
          source: { jobNames: [] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
      ],
    },
  ],
}
