export default {
  name: "Regulatory & Business Strategy",
  slug: "regulatory-business-strategy",
  jobFamilies: [
    {
      name: "Corporate Planning and Strategy",
      slug: "corporate-planning-and-strategy",
      descriptions: [
        "Supports corporate planning by conducting analyses of competitors, operational effectiveness and capacity utilization",
        "Performs strategic planning and corporate development",
        "Develops the organization's strategic plan(s) to ensure its continued successful growth and profitability",
        "Identifies, investigates, evaluates and negotiates  prospective mergers, acquisitions/divestitures, licensing opportunities and strategic business alliances that are in line with the organization's long-range goals",
        "Performs Corporate Venture Investing activities",
        "Seeks new business development opportunities",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [4, 5],
          levelText: "P4 - P5",
        },
      ],
      topSkills: [
        "Alliance Management",
        "Corporate Financial Reporting",
        "Data Management",
        "Data Visualization",
        "Financial Analysis",
        "Financial Modeling",
        "Financial Planning",
        "Mergers & Acquisitions",
        "Policy Analysis",
        "Risk Management",
        "Strategic Planning",
      ],
      potentialMovements: [
        {
          source: {
            jobNames: [
              "Lead Policy Advisory Consultant",
              "Lead Venture Investing Consultant",
            ],
          },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
      ],
    },
    {
      name: "Corporate/Financial Planning and Strategy",
      slug: "corporate-financial-planning-strategy",
      descriptions: [
        "Supports corporate planning by conducting analyses of competitors, operational effectiveness and capacity utilization",
        "May research, evaluate and analyze prospective mergers, acquisitions and divestitures",
        "Performs strategic planning and corporate development",
        "Develops the organization's strategic plan(s) to ensure its continued successful growth and profitability",
        "Identifies, investigates, evaluates and negotiates acquisitions/divestitures, licensing opportunities and strategic business alliances that are in line with the organization's long-range goals",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Corporate Financial Reporting",
        "Data Analysis",
        "Data Management",
        "Data Visualization",
        "Financial Analysis",
        "Financial Modeling",
        "Financial Planning",
        "Process Automations",
        "Risk Management",
        "Stakeholder Management",
        "Strategic Planning",
      ],
      potentialMovements: [
        {
          source: {
            jobNames: [
              "Lead Strategic Financial Consultant",
              "Lead Financial Analyst",
              "Lead Forecast Analyst",
            ],
          },
          destinations: [
            {
              jobFamilySlug: "regulatory-pricing-and-rates",
              jobNames: [
                "Lead Wholesale Services Consultant",
                "Lead Regulatory Relations and Policy Consultant",
                "Lead Retail Services Consultant",
              ],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: {
            jobNames: [
              "Sr Financial Analyst",
              "Sr Financial Systems Analyst",
              "Sr Forecast Analyst",
            ],
          },
          destinations: [
            {
              jobFamilySlug: "regulatory-pricing-and-rates",
              jobNames: ["Sr Reg Relation & Policy Consultant"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Financial Analyst II", "Forecast Analyst II"] },
          destinations: [
            {
              jobFamilySlug: "regulatory-pricing-and-rates",
              jobNames: ["Rates Analyst II"],
            },
            {
              jobFamilySlug: "resource-planning",
              jobNames: ["Resource Planning Consultant II"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Financial Analyst I", "Forecast Analyst I"] },
          destinations: [
            {
              jobFamilySlug: "regulatory-pricing-and-rates",
              jobNames: ["Rates Analyst I"],
            },
            {
              jobFamilySlug: "resource-planning",
              jobNames: ["Resource Planning Consultant I"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Regulatory Pricing and Rates",
      slug: "regulatory-pricing-and-rates",
      descriptions: [
        "Develops, analyzes, revises and administers the organization's tariff schedules",
        "Compiles, studies and maintains rate, revenue, load and customer data to assess the effectiveness of current tariff schedules and rules",
        "Develops proposals to change the organization's existing rates and rules",
      ],
      jobLevels: [
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Competitive Intelligence",
        "Customer Needs",
        "Data Analysis",
        "Data Management",
        "Data Visualization",
        "Financial Modeling",
        "Rate Design",
        "Regulatory Compliance",
        "Scenario Planning",
        "Stakeholder Management",
        "Technical Reports",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Rates Analyst II"] },
          destinations: [
            {
              jobFamilySlug: "corporate-financial-planning-strategy",
              jobNames: ["Financial Analyst II", "Forecast Analyst II"],
            },
            {
              jobFamilySlug: "resource-planning",
              jobNames: ["Resource Planning Consultant II"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Rates Analyst I"] },
          destinations: [
            {
              jobFamilySlug: "corporate-financial-planning-strategy",
              jobNames: ["Financial Analyst I", "Forecast Analyst I"],
            },
            {
              jobFamilySlug: "resource-planning",
              jobNames: ["Resource Planning Consultant I"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Resource Planning",
      slug: "resource-planning",
      descriptions: [
        "Develops and implements integrated resource planning, resource adequacy, and other strategic planning initiatives",
        "Manages the relationships with various external stakeholders to ensure the company receives favorable regulatory outcomes",
        "Provides research, development, and authoritative guidance in the areas of resource planning and adequacy",
        "Performs complex and specialized tasks relative to resource planning and adequacy related topics, issues and processes both internal and external to the Company",
        "Evaluates and recommends desirable generation investments and long-term generation plans that will enable the Company to meet its capacity and energy obligations economically, reliably and safely while meeting the Company's goals",
        "Participates in internal and external consultation and collaboration to develop appropriate generation resource adequacy metrics including thresholds and calculations, forecast types and volumes of generation the competitive electricity market is likely to require, and supports transmission planning activities.",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Business Strategies",
        "Data Analysis",
        "Data Visualization",
        "Financial Analysis",
        "Organizational Strategy",
        "Project and Program Management",
        "Regulatory Compliance",
        "Resource Planning",
        "Risk Analytics",
        "Risk Management",
        "Scenario Planning",
        "Strategic Planning",
        "Technical Reports",
        "Technology Research",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Resource Planning Consultant II"] },
          destinations: [
            {
              jobFamilySlug: "corporate-financial-planning-strategy",
              jobNames: ["Financial Analyst II", "Forecast Analyst II"],
            },
            {
              jobFamilySlug: "regulatory-pricing-and-rates",
              jobNames: ["Rates Analyst II"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
        {
          source: { jobNames: ["Resource Planning Consultant I"] },
          destinations: [
            {
              jobFamilySlug: "corporate-financial-planning-strategy",
              jobNames: ["Financial Analyst I", "Forecast Analyst I"],
            },
            {
              jobFamilySlug: "regulatory-pricing-and-rates",
              jobNames: ["Rates Analyst I"],
            },
          ],
          jobLevel: {
            bandShortForm: "P",
            level: 1,
          },
        },
      ],
    },
    {
      name: "Resource Development",
      slug: "resource-development",
      descriptions: [
        "Manage the long-term planning and development of generation resources, including integrating existing and new resource options considering the needs and interests of customers",
        "Evaluate resource options, create plans for development, consider risks, and propose projects for Leadership review and approval",
        "Perform commercial and contracting responsibilities for the acquisition of projects, origination of customer solutions and procurement of equipment to enable tax credits or mitigate long lead risks associated with projects",
        "Engage with renewable Partners and prospective customers interested in energy solutions",
      ],
      jobLevels: [
        {
          bandShortForm: "L",
          levels: [1, 2, 3],
          levelText: "L1 - L3",
        },
        {
          bandShortForm: "P",
          levels: [1, 2, 3, 4, 5],
          levelText: "P1 - P5",
        },
      ],
      topSkills: [
        "Business Acumen",
        "Community Outreach",
        "Contract Management",
        "Customer Needs",
        "Feasibility Studies",
        "Project and Program Management",
        "Risk Management",
        "Sourcing and Procurement",
        "Stakeholder Management",
        "Strategic Planning",
        "Technology Research",
      ],
      potentialMovements: [
        {
          source: { jobNames: ["Lead Technical Analysis Consultant"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 4,
          },
        },
        {
          source: { jobNames: ["Sr Renewable Development Specialist"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 3,
          },
        },
        {
          source: { jobNames: ["Renewables Development Specialist II"] },
          destinations: [],
          jobLevel: {
            bandShortForm: "P",
            level: 2,
          },
        },
      ],
    },
  ],
}
