const getJobFamilyGroupUrl = (slug, jobFamilySlug) => {
  const path = `/job-family-group/${slug}`
  return typeof jobFamilySlug !== "undefined"
    ? `${path}?jobFamilySlug=${jobFamilySlug}`
    : path
}

const getCareerBandsComparisonUrl = (slug, code) => {
  return typeof code === "undefined"
    ? `/career-bands/${slug}/comparison`
    : `/career-bands/${slug}/comparison?code=${code}`
}
const getBandDetailsUrl = (slug, code) => {
  return typeof code === "undefined"
    ? `/career-bands/${slug}`
    : `/career-bands/${slug}/code/${code}`
}
const getJobFamilyUrl = (jobFamilyGroupSlug, slug) => {
  return `/job-family-groups/${jobFamilyGroupSlug}/job-families/${slug}`
}
const getJobFamilyGroupSlugFromJobFamilySlug = (jfSlug, jobFamiliesGroups) => {
  // It takes the slug of a job family, and find its parent job family group and return the slug
  const flattenData = jobFamiliesGroups
    .filter(g => !!g)
    .map(g => {
      return {
        groupSlug: g.slug,
        jobFamilies: g.jobFamilies.map(f => f.slug),
      }
    })
  return flattenData.find(d => d.jobFamilies.includes(jfSlug)).groupSlug
}

const getBandDetailsUrlFromComparisonUrl = url => {
  const split = url.split("/")
  const slug = split[split.indexOf("career-bands") + 1]
  return getBandDetailsUrl(slug, `${slug[0].toUpperCase()}1`)
}

export {
  getJobFamilyGroupUrl,
  getCareerBandsComparisonUrl,
  getBandDetailsUrl,
  getJobFamilyUrl,
  getJobFamilyGroupSlugFromJobFamilySlug,
  getBandDetailsUrlFromComparisonUrl,
}
